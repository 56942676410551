/* eslint-disable */
import React, { useState } from 'react';
import { EditPageHeader } from '../../components/EditPageHeader';
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import BossDialog from '../../components/BossDialog';

const TxModeEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Code: props.item.Code ?? "",
        IsTypeLive: props.item.IsTypeLive ?? false,
        Archive: props.item.Archive ?? false
    }

    const [dataItem, setDataItem] = useState(blankDataItem);

    const onChange = (e) => {
        if (e.target.name == "IsTypeLive") {
            setDataItem({ ...dataItem, IsTypeLive: !dataItem.IsTypeLive });
        } else if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        if (isValid()) {
            const localData = {
                _id: dataItem._id,
                SID: dataItem.SID ?? 0,
                Name: dataItem.Name,
                Code: dataItem.Code,
                IsTypeLive: dataItem.IsTypeLive ?? false,
                Archive: dataItem.Archive ?? false,
            }
            let res = await API.saveData(ENTITYNAME.TXMode, localData);
            console.log(res);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.TX_MODE, data: res.data, message: res.message };
                API.SaveLogs(logData);
                props.refresh();
                props.cancelEdit();
                utility.deleteLocalStorageItem(ENTITYNAME.TXMode);
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <>
            <BossDialog
                title={dataItem.Name ?? lang.create_tx_mode_dialog_header}
                onClose={props.cancelEdit}
                width={"600px"}
            >
                <div className='row mt-2'>
                    <div className='col-12'><EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <label>{lang.name_label} *</label>
                                <input
                                    name={"Name"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Name}
                                />
                            </div>
                            <div className='col-6'>
                                <label>{lang.code_label}</label>
                                <input
                                    name={"Code"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Code}
                                />
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-3">
                                <input
                                    type="checkbox"
                                    name="IsTypeLive"
                                    value={dataItem.IsTypeLive}
                                    checked={dataItem.IsTypeLive}
                                    onChange={onChange}
                                />
                                <label htmlFor="TabView" className="pl-2">{lang.is_type_live_label}</label>
                            </div>
                            <div className="col">
                                <input
                                    type="checkbox"
                                    name="Archive"
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive}
                                    onChange={onChange}
                                />
                                <label htmlFor="TabView" className="pl-2">{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog>
        </>
    )
}

export default TxModeEditForm;