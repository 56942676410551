/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ASSURANCETYPE, ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { utility } from "../../framework/utility/utilityProvider";
import { DropDownCell } from "../Contract/ContractCommandCell";
import moment from "moment";

function OptimisationEditForm(props) {

    const { SID } = useParams();

    let blankDataItem = {
        SID: 0,
        Channel: {},
        Archive: false,
        BookingDate: ''
    }

    const [channel, setChannel] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [editID, setEditID] = useState(null);
    const [editable, setEditable] = useState(true);

    const navigate = useNavigate();
    const lang = useTranslation();

    const [dataItem, setDataItem] = useState(blankDataItem)

    useEffect(() => {
        loadcombo();
        if (SID > 0) {
            loadEditData();
        }
    }, []);

    const loadcombo = async () => {
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
        setDataItem({ ...blankDataItem, Channel: utility.getDefaultItem(channelRes.data) })
    }

    const loadEditData = async () => {
        let res = await API.getEntity(ENTITYNAME.SalesOptimizationTemplate, parseInt(SID));
        console.log(res.data)
        if (res.success) {
            setDataItem({
                ...res.data,
                BookingDate: moment(new Date(res.data.Date)).format("YYYY-MM-DD"),
            })
            setGridData(res.data.Details);
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (gridData.length == 0) {
            toast.error(`${lang.please_show_atleast_one_item_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        return true
    }

    const onSave = async () => {

        if (isValid()) {
            const saveData = {
                SID: dataItem.SID,
                Channel: { _id: dataItem.Channel._id, SID: dataItem.SID, FullChannelName: dataItem.Channel.FullChannelName },
                Date: new Date(dataItem.BookingDate).getTime(),
                Archive: dataItem.Archive ?? false,
                Details: gridData
            }
            console.log(saveData)
            let res = await API.saveData(ENTITYNAME.SalesOptimizationTemplate, saveData);
            console.log(res)
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.OPTIMISATION, data: res.data, message: res.message };
                API.SaveLogs(logData);
                navigate(-1);
                utility.deleteLocalStorageItem(ENTITYNAME.SalesOptimizationTemplate);
                return;
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const filterBookingData = async () => {
        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (!utility.isValidDate(dataItem.BookingDate)) {
            toast.error(`${lang.please_select_booking_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let Payload =
        {
            "channelSID": dataItem.Channel.SID,
            "BookingDate": new Date(dataItem.BookingDate).getTime(),
        }
        let res = await API.loadBookingData(Payload);

        if (res.success) {
            const groupByAgency = res.data.reduce((group, booking) => {
                const agency = booking.campaign[0].Agency.Name;
                group[agency] = group[agency] ?? [];
                group[agency].push(booking);
                return group;
            }, {});
            let agencyKeys = Object.keys(groupByAgency)
            let data = agencyKeys.map(item => {
                let totalFCTCalculate = 0;
                let Agency_id = "";
                groupByAgency[item]?.map(object => {
                    Agency_id = object.campaign[0].Agency._id
                    totalFCTCalculate = totalFCTCalculate + object.MediaEpisode.Duration;
                })
                return {
                    Agency: { _id: Agency_id, Name: item },
                    TotalSpots: groupByAgency[item].length,
                    TotalFCT: totalFCTCalculate / 1000,
                }
            })
            console.log(data);
            setGridData(data);
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });

        }

    }

    const MyDropDownCell = (props) => {
        let disable = props.editor;
        return <>{disable ?
            <DropDownCell
                {...props}
                data={ASSURANCETYPE}
            />
            :
            <input
                disabled={true} value={ASSURANCETYPE.Description ?? "NA"} style={{ fontSize: "12px", margin: "20px 0px 0px 10px" }}></input>

        }</>
    }

    const rowClick = (event) => {
        setEditID(event.dataItem.Agency._id);
    };

    const itemChange = (event) => {
        console.log(event);
        if (event.dataItem?.AssuranceType?.Description == "Percentage") {
            if (event.value > 100 || event.value < 0) {
                toast.error(`${lang.please_enter_valid_percentage_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                event.value = 0;
            }
        }
        if (event.dataItem?.AssuranceType?.Description == "Absolute") {
            if (event.value > event.dataItem.TotalFCT) {
                toast.error(`${lang.assured_value_is_greater_than_total_fct_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                event.value = event.dataItem.TotalFCT;
            }
            if (event.value < 0) {
                toast.error(`${lang.assured_value_is_less_than_zero_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                event.value = 0;
            }
        }
        const inEditID = event.dataItem.Agency._id;
        const field = event.field || "";
        const newData = gridData.map((item) =>
            item.Agency._id === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        setGridData(newData);
    };

    return (<>
        <div className="row m-1">
            <div className="col-12">
                <EditPageHeader title={lang.optimisation_label} onSubmit={onSave} onCancel={() => navigate(-1)} />
                <div className="row mt-2">
                    <div className="col-3">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-3">
                        <label htmlFor="TabView">{lang.booking_date_label} *</label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="BookingDate"
                            value={dataItem.BookingDate}
                            onChange={onChange}
                        />
                    </div>
                    <div className="col" style={{ marginTop: '22px' }}>
                        <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} onClick={filterBookingData} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <input type={"checkbox"} name={"Archive"}
                            value={dataItem.Archive}
                            checked={dataItem.Archive}
                            onChange={onChange} />
                        <label className='ml-1'>{lang.archive}</label>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <Grid style={{ height: "63vh", marginTop: "04px", overflow: "hidden" }}
                                    data={gridData?.map((item) => ({
                                        ...item,
                                        inEdit: item.Agency?._id === editID,
                                    }))}
                                    editField="inEdit"
                                    onRowClick={rowClick}
                                    onItemChange={itemChange}>
                                    <Column field="Agency.Name" title={lang.agency_name_column} editable={false} />
                                    <Column field="TotalSpots" title={lang.total_spot_column} editable={false} />
                                    <Column field="TotalFCT" title={lang.total_fct_column} editable={false} />
                                    <Column field="AssuranceType" title={lang.assurance_type_column} cell={MyDropDownCell} editable={editable} editor={editable} />
                                    <Column field="AssuredValue" title={lang.assured_value_column} editor="numeric" editable={editable} />
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default OptimisationEditForm;