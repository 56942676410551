/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from '../../framework/API/api';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ENTITYNAME, MODULETYPES, INGESTIONTEMPLATETYPES, INGESTION_MODULETYPES, FILEURL, MEDIACATEGORIES, LOGEVENT, MODULE } from "../../framework/constant/constant";
import IngestionTemplateBottomSection from "./SubComponents/IngestionTemplateBottomSection";
import IngestionTemplateMiddleSection from "./SubComponents/IngestionTemplateMiddleSection";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from "react";
import InfoRoundButton from "../../components/InfoRoundButton";
import { utility } from "../../framework/utility/utilityProvider";

const IngestionTemplate = () => {

  const { SID } = useParams();
  const lang = useTranslation();
  const navigate = useNavigate();
  const loc = useLocation();


  var blankDataItem = {
    TemplateName: "",
    Url: "",
    RequestType: { SID: 1, Description: "GET" },
    Response: "",
    Body: "",
    ModuleType: {},
    Content: {},
    Channel: {},
    Provider: {},
    Archive: false,
    IsSports: false,
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [paramsValues, setParamsValues] = useState([]);

  // to store api call response keys
  const [keysList, setKeysList] = useState([]);
  const [restrictions, setRestrictions] = useState([]);

  // TO GET REQUEST AND RESPONSE FROM MIDDLE SECTION PAGE
  const [requestResponseDetails, setRequestResponseDetails] = useState({});
  const contentsDataRef = useRef([]);
  const [contents, setContents] = useState([]);
  const channelsDataRef = useRef([]);
  const [channels, setChannels] = useState([]);
  const providerDataRef = useRef([]);
  const [providers, setProviders] = useState([]);

  const [isAPISelected, setIsAPISelected] = useState(true);
  const [isContentRequired, setIsContentRequired] = useState(false);
  const [isChannelRequired, setIsChannelRequired] = useState(false);
  const [isProviderRequired, setIsProviderRequired] = useState(false);
  const [isSelectManualDate, setIsSelectManualDate] = useState(false);
  const [isOtt, setIsOtt] = useState(false);

  // const[isAsRunSelected,setIsAsRunSelected]=useState(false);
  const [formFields, setFormFields] = useState([]);

  //TO GET THE MAPPED KEYS FROM BOTTOM SECTION
  const [mapping, setMapping] = useState([]);


  useEffect(() => {

    loadCombo();
    if (SID > 0) {
      loadEditData();
    }

  }, [])


  const setContentsData = (contents) => {
    contentsDataRef.current = contents;
    setContents(contents)
  }

  const setChannelsData = (channels) => {
    channelsDataRef.current = channels;
    setChannels(channels)
  }

  const setProvidersData = (providers) => {
    providerDataRef.current = providers;
    setProviders(providers)
  }


  const getFormFields = async (entityName, issports = false, isOttAsset = false) => {

    let query = issports ? ["entityName", "=", "sports"] : [["entityName", "=", entityName], ["IsImportable", "=", true]];

    let columns = [];
    let res = await API.getData(ENTITYNAME.FormFields, { query: query });

    columns = [...res.data];
    if (isOttAsset) {

      let resOtt = await API.getData(ENTITYNAME.FormFields, { query: [["entityName", "=", "assetIngestion"], ["IsImportable", "=", true]] });
      let temp = resOtt.data.filter((item) => item.label != "Asset Type" && item.label != "Access Level");
      columns = [...res.data, ...temp];

    }

    //NEED TO REMOVE DUPLICATE OBJECT FROM ARRAY ON BASIS OF label field
    columns = [...new Map(columns.map(item => [item['label'], item])).values()];
    setFormFields(columns);

  }

  const loadCombo = async () => {
    let contents = await API.getDataLookup(ENTITYNAME.Content);
    setContentsData(contents.data);

    let channelsRes = await API.getDataLookup(ENTITYNAME.Channel);
    setChannelsData(channelsRes.data);

    let providersRes = await API.getDataLookup(ENTITYNAME.OttProvider);
    setProvidersData(providersRes.data);

  }
  const handleChange = (e) => {

    if (e.target.name == "IsSports") {
      setDataItem({ ...dataItem, [e.target.name]: !dataItem.IsSports });
    } else if (e.target.name == "IngestionTemplateType") {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value })
      e.target.value.Description.toLowerCase() == "api" ? setIsAPISelected(true) : setIsAPISelected(false);
    } else if (e.target.name == "ModuleType") {

      setIsOtt(false);
      // FETCHING DATA FROM FORM FIELD TO CREATE DROPDOWNS FOR TEMPLATE DETAILS

      getFormFields(e.target.value.EntityName);
      // UPDATING PROPERTY TO SHOW/HIDE CONTENT DROPDOW
      e.target.value.IsContentRequired ? setIsContentRequired(true) : setIsContentRequired(false);

      e.target.value.IsChannelRequired ? setIsChannelRequired(true) : setIsChannelRequired(false);

      e.target.value.IsProviderRequired ? setIsProviderRequired(true) : setIsProviderRequired(false);

      e.target.value.IngestionTemplateType != undefined ?
        setDataItem({
          ...dataItem,
          IngestionTemplateType: Object.keys(INGESTIONTEMPLATETYPES).map((x) => { return { SID: INGESTIONTEMPLATETYPES[x], Description: x } }).find((y) => y.SID == e.target.value.IngestionTemplateType),
          [e.target.name]: e.target.value
        }) : setDataItem({ ...dataItem, IngestionTemplateType: {}, [e.target.name]: e.target.value });



      // FILTERING CONTNET BASED ON SELECTED MODULE TYPE'S MEDIA CATEGORY
      if (e.target.value.IsContentRequired) {
        var _contents = contentsDataRef.current.filter(function (content) {
          return content.MediaCategoryType.MediaCategorySID == e.target.value.MediaCategorySID;
        });

        setContents(_contents);
      }

    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

  };

  const loadEditData = async () => {
    const res = await API.getEntity(ENTITYNAME.PartnerIntegrationTemplate, parseInt(SID));

    if (res.success) {

      if (res.data.ModuleType.IsContentRequired) {
        setIsContentRequired(true);

        let _contents = contentsDataRef.current.filter(function (content) {
          return content.MediaCategoryType.MediaCategorySID == res.data.ModuleType.MediaCategorySID;
        });
        setContents(_contents);
      }

      if (res.data.ModuleType.IsChannelRequired) {
        setIsChannelRequired(true);
      }

      if (res.data.ModuleType.IsProviderRequired) {
        setIsProviderRequired(true);
      }

      setDataItem({
        ...blankDataItem,
        ...res.data,
        SID: parseInt(SID),
        TemplateName: loc.state?.copy ? 'Copy of ' + res.data.TemplateName : res.data.TemplateName,

      });
      getFormFields(res.data.ModuleType.EntityName, false, res.data.isOtt);
      res.data.IngestionTemplateType.Description.toLowerCase() == "api" ? setIsAPISelected(true) : setIsAPISelected(false);

      setRequestResponseDetails({
        RequestType: res.data.RequestType,
        Url: res.data.Url,
        Body: res.data.Body,
        Response: res.data.Response,
        ResponseKeysObject: res.data.ResponseKeysObject,
        IsCurl: res.data.IsCurl,
        Curl: res.data.Curl
      })

      setParamsValues(res.data.ParamKeys);
      setMapping(res.data.Mapping);

      setIsOtt(res.data.isOtt);
      setRestrictions(res.data?.Restrictions ?? []);


    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const getParams = (data) => {
    setParamsValues(data);
  }

  const getKeysList = (kyesList) => {
    setKeysList(kyesList);
  }

  const setMappingKeys = (keys) => {
    setMapping(keys);
  }

  const updateRequestResponseDetail = (data) => {
    setRequestResponseDetails(data);
  }

  const isValidToSave = () => {

    //TEMPLATE NAME
    if (dataItem.TemplateName === 'undefined' || dataItem.TemplateName == "") {
      toast.error(`${lang.please_enter_template_name_error_toast}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }
    //MediaCategory
    // if(dataItem.ModuleType.Description.toLowerCase() == "medialibrary" && typeof dataItem.MediaCategory === 'undefined')
    // {
    //   setMessage("Please Select Media Category");
    //   handleAlertBox();
    //   return false;
    // }
    //ModuleType
    if (typeof dataItem.ModuleType === 'undefined') {
      toast.error(`${lang.please_select_module_type_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    //Content
    if ((dataItem.ModuleType.IsContentRequired == undefined || dataItem.ModuleType.IsContentRequired) && (typeof dataItem.Content === 'undefined' || dataItem.Content.Prefix == "")) {
      toast.error(`${lang.please_select_content_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    //IngestionTemplateType
    if (typeof dataItem.IngestionTemplateType === 'undefined') {
      toast.error(`${lang.please_select_ingestion_template_type_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const saveTemplate = async (e) => {

    if (!isValidToSave()) return;

    let saveData = {
      ...dataItem,
      SID: loc.state?.copy ? 0 : parseInt(SID),
      RequestType: requestResponseDetails.RequestType ? requestResponseDetails.RequestType : {},
      Url: requestResponseDetails.Url,
      Body: requestResponseDetails.Body,
      ParamKeys: paramsValues,
      Response: requestResponseDetails.Response,
      Mapping: mapping,
      ResponseKeysObject: requestResponseDetails.ResponseKeysObject,
      IsCurl: requestResponseDetails.IsCurl,
      Curl: requestResponseDetails.Curl,
      PartnerKeysList: keysList,
      Content: isContentRequired && Object.keys(dataItem.Content).length > 0 ? {
        _id: dataItem.Content._id,
        SID: dataItem.Content.SID,
        Description: dataItem.Content.Description,
        MediaCategoryType: dataItem.Content.MediaCategoryType,
      } : {},
      Channel: isChannelRequired && Object.keys(dataItem.Channel).length > 0 ? {
        _id: dataItem.Channel._id,
        SID: dataItem.Channel.SID,
        FullChannelName: dataItem.Channel.FullChannelName,
      } : {},
      Provider: isProviderRequired && dataItem?.Provider && Object.keys(dataItem?.Provider).length > 0 ? {
        _id: dataItem?.Provider?._id,
        Description: dataItem?.Provider?.Description,
        LogoUrl: dataItem?.Provider?.LogoUrl
      } : {},
      Restrictions: restrictions,
      isSelectManualDate: isSelectManualDate ?? false,
      isOtt: isOtt ?? false
    };

    if (loc?.state?.copy) delete saveData._id

    // saveData.SID = SID;
    const res = await API.saveData(ENTITYNAME.PartnerIntegrationTemplate, saveData);

    if (res.success) {
      let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.INGESTION_TEMPLATE, data: res.data, message: res.message };
      API.SaveLogs(logData);
      toast.success(`${lang.saved_successfully_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      utility.deleteLocalStorageItem(ENTITYNAME.PartnerIntegrationTemplate);
      navigate(-1);
    }

  }

  const handleCancel = () => {
    navigate(-1);
  }

  const downloadDataTemplate = () => {
    if (dataItem.ModuleType.SID == "" || dataItem.ModuleType.SID == undefined) {
      toast.error(lang.please_select_module_type_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      switch (dataItem.ModuleType.SID) {
        case INGESTION_MODULETYPES.Schedule:
          var filePath = FILEURL.BASEURL + "downloadTemplate/" + "ImportScheduleIngestionTemplate.xlsx";
          window.open(filePath);
          break;

        case INGESTION_MODULETYPES.PlanningGrid:
          var filePath = FILEURL.BASEURL + "downloadTemplate/" + "ImportPlanningGridTemplate.xlsx";
          window.open(filePath);
          break;

        default:
          break;
      }
    } catch (error) {
      toast.error(`${lang.error_downloading_template_booking_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div>
      <div className="row mt-1">

        <div className="col-md-2 col-lg-2 col-sm-4 col-xs-4 mb-2">
          <label><strong>{lang.template_name_label} *</strong></label>
          <input type="text" className="form-control form-control-md" name="TemplateName" value={dataItem.TemplateName} onChange={(e) => handleChange(e)} />
        </div>


        <div className="col-md-2 col-lg-1 col-sm-4 col-xs-4" style={{ padding: '0px' }}>
          <label><strong>{lang.module_type_label} *</strong></label>
          <DropDownList
            style={{
              height: "38px",
              backgroundColor: "white"
            }}
            dataItemKey="SID"
            data={MODULETYPES}
            name="ModuleType"
            textField="Description"
            value={dataItem.ModuleType}
            onChange={handleChange}
          />
        </div>


        {isContentRequired &&
          <div className="col-md-2 col-lg-1 col-sm-4 col-xs-4" style={{ padding: '0px 0px 0px 15px' }}>
            <label><strong>{lang.content_label} *</strong></label>
            <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip + " (Prefix - " + dataItem.Content.Prefix + ", Suffix - " + dataItem.Content.Suffix + ")"}></InfoRoundButton>
            <DropDownList
              style={{
                height: "38px",
                backgroundColor: "white"
              }}
              dataItemKey="SID"
              data={contents}
              name="Content"
              textField="Description"
              value={dataItem.Content}
              onChange={handleChange}
            />
          </div>
        }

        {isChannelRequired &&
          <div className="col-md-2 col-lg-1 col-sm-4 col-xs-4" style={{ padding: '0px 0px 0px 15px' }}>
            <label><strong>{lang.channel_label} *</strong></label>
            <DropDownList
              style={{
                height: "38px",
                backgroundColor: "white"
              }}
              dataItemKey="_id"
              data={channels}
              name="Channel"
              textField="FullChannelName"
              value={dataItem.Channel}
              onChange={handleChange}
            />
          </div>}

        {isOtt && isProviderRequired &&
          <div className="col-md-2 col-lg-1 col-sm-4 col-xs-4" style={{ padding: '0px 0px 0px 15px' }}>
            <label><strong>{lang.provider_sub_menu} </strong></label>
            <DropDownList
              style={{
                height: "38px",
                backgroundColor: "white"
              }}
              dataItemKey="_id"
              data={providers}
              name="Provider"
              textField="Description"
              value={dataItem.Provider}
              onChange={handleChange}
            />
          </div>}

        <div className="col-md-2 col-lg-2 col-sm-4 col-xs-4 " style={{ padding: '0px 0px 0px 15px' }}>
          <label><strong>{lang.ingestion_template_type_label} *</strong></label>
          <DropDownList
            style={{
              height: "38px",
              backgroundColor: "white"
            }}
            dataItemKey="SID"
            data={Object.keys(INGESTIONTEMPLATETYPES).map((x) => { return { SID: INGESTIONTEMPLATETYPES[x], Description: x } })}
            name="IngestionTemplateType"
            textField="Description"
            value={dataItem.IngestionTemplateType}
            onChange={handleChange}
            disabled={dataItem.ModuleType && dataItem.ModuleType.IngestionTemplateType != undefined}
          />
        </div>

        {dataItem.ModuleType && dataItem.ModuleType.SID == INGESTION_MODULETYPES.Schedule && <div className="col-md-2 col-lg-2 col-sm-4 col-xs-4" style={{ marginTop: '35px', paddingRight: "0px " }}>
          <input type={"checkbox"} name={"isSelectManualDate"} content={"fgvegv"}
            onChange={(e) => setIsSelectManualDate(e.target.checked)}
            value={isSelectManualDate}
            style={{ marginTop: "0px", marginLeft: "15px", alignItems: "center", width: "15px", height: "15px" }}
            checked={isSelectManualDate} />
          <label style={{ margin: "0px 5px 10px 5px" }}><strong>{"Select Date Manually"} </strong></label>

        </div>}

        {dataItem.ModuleType && dataItem.ModuleType.IsContentRequired && <div className="col-md-2 col-lg-1 col-sm-4 col-xs-4" style={{ marginTop: '35px', paddingRight: "0px " }}>
          <input type={"checkbox"} name={"isOtt"}
            onChange={(e) => { setIsOtt(e.target.checked); getFormFields(dataItem.ModuleType.EntityName, false, e.target.checked); }}
            value={isOtt}
            style={{ marginTop: "0px", marginLeft: "15px", alignItems: "center", width: "15px", height: "15px" }}
            checked={isOtt} />
          <label style={{ margin: "0px 5px 10px 5px" }}><strong>{"Is Ott"} </strong></label>
        </div>}

        {dataItem.ModuleType && (dataItem.ModuleType.MediaCategorySID == MEDIACATEGORIES.Season || dataItem.ModuleType.MediaCategorySID == MEDIACATEGORIES.Program || dataItem.ModuleType.MediaCategorySID == MEDIACATEGORIES.Episode) &&
          <div className="col-md-2 col-lg-1 col-sm-4 col-xs-4" style={{ marginTop: '35px', paddingRight: "0px " }}>
            <input type={"checkbox"} name={"IsSports"}
              onChange={handleChange}
              value={dataItem.IsSports}
              style={{ marginTop: "0px", marginLeft: "15px", alignItems: "center", width: "15px", height: "15px" }}
              checked={dataItem.IsSports} />
            <label style={{ margin: "0px 5px 10px 5px" }}><strong>{"Is Sports"}</strong></label>
          </div>}

        <div className="col-2" style={{ marginTop: "25px", padding: '0px 0px 0px 25px' }}>
          <button title={lang.save_button_tooltip} type="button" onClick={(e) => saveTemplate(e)} className="btn-square btn-success" style={{ marginLeft: "-10px" }}>
            {lang.save_template_text}
          </button>
          <button title={lang.cancel_button_tooltip} type="button" onClick={handleCancel} className="btn-square btn-danger" style={{ marginLeft: "5px" }}>
            {lang.cancel_button_text}
          </button>
          {dataItem.ModuleType.SID && (dataItem.ModuleType.SID == INGESTION_MODULETYPES.Schedule || dataItem.ModuleType.SID == INGESTION_MODULETYPES.PlanningGrid) && <button type="button" title={lang.download_template_tooltip} onClick={downloadDataTemplate} className={`btn btn-default bg-primary btn-circle btn-my ml-2`}>
            <i className={`fa fa-download fa-fw img-circle`} style={{ color: "white" }}></i>
          </button>}
        </div>
      </div>

      <div className="row">
        <IngestionTemplateMiddleSection isAPISelected={isAPISelected} SID={SID} getKeysList={getKeysList} updateRequestResponseDetail={updateRequestResponseDetail} getParams={getParams} headerDataItem={dataItem} />
        <IngestionTemplateBottomSection isAPISelected={isAPISelected} keysList={keysList} SID={SID} formFields={formFields} mapping={mapping} setMappingKeys={setMappingKeys} restrictions={restrictions} setRestrictions={setRestrictions} moduleType={dataItem.ModuleType} />
      </div>
    </div>
  )
};

export default IngestionTemplate;
