/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import { BOOKINGSTATUS, ENTITYNAME, FILEURL, LOCALSTORAGE_KEY, LOGEVENT, MEDIACATEGORIES, MEDIACATEGORYTYPES, MODULE, SCHEDULETYPE } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import moment from "moment";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { EditPageHeader } from "../../components/EditPageHeader";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import MyStatusCell from "../../components/MyStatusCell";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { SchedulingHelper } from "../Scheduling/helper/SchedulingHelper";
import { orderBy } from "@progress/kendo-react-data-tools";
import BossDialog from "../../components/BossDialog";

export const AnnouncementReconcileCollection = (props) => {
  //possible props
  var showBack = props.showBack ?? true;
  var showTitle = props.showTitle ?? true;
  var filterable = props.filterable ?? false;
  var sortable = props.sortable ?? false;
  var myTitle = props.title ?? "";
  // var myMenuModel = props.menuModel ?? collectionMenuModel;
  const lang = useTranslation();
  const navigate = useNavigate();

  const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
  const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
  const offsetTime = offSetHours * 3600000;

  const [announcementData, setAnnouncementData] = useState([]);
  const [recordedCommercial, setRecordedCommercial] = useState([]);
  const [showData, setShowData] = useState([]);
  const [total, setTotal] = useState(0);
  const [tab, setTab] = useState('1');
  const [isLoading, setIsLoading] = useState(false);
  const [isNoRecords, setIsNoRecords] = useState(false);
  const [channels, setChannels] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [isHeaderEditPopup, setIsHeaderEditPopup] = useState(false);
  const [showInHouseData, setShowInHouseData] = useState([]);
  //ASC = false and DESC = true
  const [sortShowData, setSortShowData] = useState([
    {
      field: "SlotDateTime",
      dir: "asc",
    },
  ])
  const [sortRecordedData, setSortRecordedData] = useState([
    {
      field: "NominalOnAir",
      dir: "asc",
    },
  ])
  const [sortAnnouncementData, setSortAnnouncementData] = useState([
    {
      field: "NominalOnAir",
      dir: "asc",
    },
  ])
  const blankDataItem = {
    Reconcile: false,
    ActualOnAir: "00:00:00:00",
    ActualDuration: "00:00:00:00",
    Comment: "",
  }
  const [editDataItem, setEditDataItem] = useState(blankDataItem);

  var selectedDataItem = useRef(0);
  const setSelectedDataItem = (data) => {
    selectedDataItem.current = data;
  };

  const [dataItem, setDataItem] = useState({
    Date: moment(new Date()).format("YYYY-MM-DD"),
    Channel: "",
  });


  useEffect(() => {
    loadcombo();
  }, []);

  const loadcombo = async () => {
    var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    setChannels(channelRes?.data);
    setDataItem({ ...dataItem, Channel: utility.getDefaultItem(channelRes.data) })
  };

  const loaddata = async (filterQuery) => {
    try {
      setIsLoading(true);
      var res = await API.getScheduledAnnouncement(filterQuery);
      let tempData = [];
      if (res.data && res.data.length > 0) {
        tempData = res.data.map((x) => {
          return {
            ...x,
            BookingStatusSID: utility.enumObj(x.BookingStatusSID, BOOKINGSTATUS)
          }
        });
      }
      setAnnouncementData(tempData.filter((x) => x.MediaEpisode.MediaCategoryType.SID == MEDIACATEGORYTYPES.Announcement));
      setRecordedCommercial(tempData.filter((x) => x.MediaEpisode.MediaCategoryType.SID != MEDIACATEGORYTYPES.Announcement))
      setTotal(tempData.length);
      setIsLoading(false);
      setIsNoRecords(false);

      //GET SHOWS
      var payload = SchedulingHelper.getPayload(
        new Date(dataItem.Date).getTime(),
        dataItem.Channel.SID,
        offsetTime
      );
      console.log(payload)
      var showDataRes = await API.getSchedulingHeaders(payload);
      console.log(showDataRes)
      setShowData(showDataRes.data)
      var showInHouseDataRes = showDataRes.data.filter((x) => x.mediaEpisode.MediaCategoryType.SID == MEDIACATEGORYTYPES.InHouse);
      setShowInHouseData(showInHouseDataRes)

    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setIsNoRecords(true);
    }
  };

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  };

  const isValidFilter = () => {
    if (Object.keys(dataItem.Channel).length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (!utility.isValidDate(dataItem.Date)) {
      toast.error(`${lang.please_select_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const showFilterData = () => {
    if (isValidFilter()) {
      var filterQuerys = {
        ChannelSID: dataItem.Channel.SID,
        BookingDate: new Date(dataItem.Date).getTime()
      }
      loaddata(filterQuerys);
    }
  };

  const enterEdit = (item) => {
    setSelectedDataItem(item);
    setEditDataItem({
      Reconcile: item.BookingStatusSID._id == BOOKINGSTATUS.Reconciled,
      ActualOnAir: utility.convertMilisecondsToStringWithFrames(item.NominalOnAir),
      ActualDuration: utility.convertMilisecondsToStringWithFrames(item.MediaEpisode.Duration),
      Comment: item.Comment,
    });
    setShowEditForm(true);
  };

  const reconcile = async (item) => {
    var data = {
      ...item,
      BookingStatusSID: item.BookingStatusSID._id != BOOKINGSTATUS.Reconciled ? BOOKINGSTATUS.Reconciled : BOOKINGSTATUS.Scheduled,
      ActualOnAir: item.BookingStatusSID._id != BOOKINGSTATUS.Reconciled ? item.NominalOnAir : 0,
    }
    var res = await API.saveData(ENTITYNAME.Booking, data);
    if (res.success) {
      announcementData.map((x) => {
        if (x._id == res.data._id) {
          x.BookingStatusSID = utility.enumObj(res.data.BookingStatusSID, BOOKINGSTATUS);
          x.ActualOnAir = item.BookingStatusSID._id != BOOKINGSTATUS.Reconciled ? item.NominalOnAir : 0;
        }
      })
      setAnnouncementData([...announcementData]);
      toast.success(lang.reconciled_successfully_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  const onAddRemarksForHeader = (item) => {
    setShowEditForm(true);
    setIsHeaderEditPopup(true);
    setEditDataItem(item);
  }

  const onSubmitHeaderComment = async () => {
    // editHeaderItem
    var res = await API.saveData(ENTITYNAME.ProgramSchedule, { _id: editDataItem._id, Comment: editDataItem.Comment ?? '' });
    if (res.success) {
      showData.map((x) => {
        if (x._id == res.data._id) {
          x.Comment = editDataItem.Comment;
        }
      })
      setShowData([...showData]);
      setShowInHouseData([...showInHouseData]);
      toast.success(lang.update_successfully_grid_data_success_toast_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowEditForm(false);
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const collectionAnnouncementReconcileMenuModel = (props) => {

    const menu = [
      { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem }) },
    ]

    if (tab == 1) {
      menu.push({ label: `${props.dataItem.NominalOnAir > 0 && props.dataItem.ActualOnAir > 0 ? 'Not Reconcile' : props.dataItem.NominalOnAir > 0 ? 'Reconcile' : 'None'}`, icon: 'pi pi-fw pi-sync', command: () => props.reconcile({ ...props.dataItem }) })
    }

    return menu;
  }

  const collectionShowCollectionMenuModel = (props) => {
    const menu = [
      { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.onAddRemarksForHeader({ ...props.dataItem }) },
    ]

    return menu;
  }

  const MyCommandCell = (props) => (
    <MenuCommandCell {...props} enterEdit={enterEdit} reconcile={reconcile} myMenuModel={collectionAnnouncementReconcileMenuModel} style={{ backgroundColor: "white" }} />
  );

  const ShowCommandCell = (props) => (
    <MenuCommandCell {...props} onAddRemarksForHeader={onAddRemarksForHeader} myMenuModel={collectionShowCollectionMenuModel} style={{ backgroundColor: "white" }} />
  );

  const onChangeEdit = (e) => {
    if (e.target.name == "Reconcile") {
      setEditDataItem({ ...editDataItem, Reconcile: !editDataItem.Reconcile });
    } else {
      setEditDataItem({ ...editDataItem, [e.target.name]: e.target.value });
    }
  }

  const handleSubmit = async () => {
    var gridData = tab == 1 ? [...announcementData] : [...recordedCommercial]

    if (tab == 1 && editDataItem.Reconcile && (editDataItem.ActualOnAir == "00:00:00:00" || editDataItem.ActualOnAir == undefined)) {
      toast.error(`${lang.please_enter_actualonair_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (tab == 1 && editDataItem.Reconcile && (editDataItem.ActualDuration == "00:00:00:00" || editDataItem.ActualDuration == undefined)) {
      toast.error(`${lang.please_enter_valid_actual_duration_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    var data = {
      ...selectedDataItem.current,
      BookingStatusSID: editDataItem.Reconcile ? BOOKINGSTATUS.Reconciled : BOOKINGSTATUS.Scheduled,
      ActualDuration: editDataItem.Reconcile ? utility.convertStringWithFramesToMilliseconds(editDataItem.ActualDuration) : 0,
      ActualOnAir: editDataItem.Reconcile ? utility.convertStringWithFramesToMilliseconds(editDataItem.ActualOnAir) : 0,
      Comment: editDataItem.Comment,
    }
    var res = await API.saveData(ENTITYNAME.Booking, data);
    if (res.success) {
      gridData.map((x) => {
        if (x._id == res.data._id) {
          x.BookingStatusSID = utility.enumObj(res.data.BookingStatusSID, BOOKINGSTATUS);
          x.ActualOnAir = res.data.ActualOnAir;
          x.Comment = res.data.Comment;
        }
      });

      tab == 1 ? setAnnouncementData([...gridData]) : setRecordedCommercial([...gridData])
      setShowEditForm(false);
      setEditDataItem(blankDataItem);
      toast.success(lang.update_successfully_grid_data_success_toast_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.ANNOUNCEMENT_RECONCILE, data: res.data, message: res.message };
      API.SaveLogs(logData);
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const rowRender = (trElement, props) => {

    const available = props.dataItem.BookingStatusSID._id == BOOKINGSTATUS.Reconciled;

    const trProps = {
      style: available ? { backgroundColor: "rgb(55, 180, 0,0.32)" } : { backgroundColor: "white" }
    };
    return React.cloneElement(
      trElement,
      {
        ...trProps,
      },
      trElement.props.children
    );
  };

  const StatusCell = (props) => (
    <MyStatusCell {...props}
      title={props.dataItem.NominalOnAir > 0 && props.dataItem.ActualOnAir > 0 ? 'Reconciled' : props.dataItem.NominalOnAir > 0 ? 'Scheduled' : 'None'}
      titleColor='#ffffff'
      titleFontSize='12px'
      tileBackColor={props.dataItem.NominalOnAir > 0 && props.dataItem.ActualOnAir > 0 ? "#1ca666" : props.dataItem.NominalOnAir > 0 ? "#f58d05" : '#5C469C'}
    // title={props.dataItem.BookingStatusSID.Description} 
    // tileBackColor={props.dataItem.BookingStatusSID._id == BOOKINGSTATUS.Scheduled ? "#f58d05" : props.dataItem.BookingStatusSID.Description == 'Done' ? "#1ca666" : '#5C469C'}
    />
  )

  const downloadExcel = async () => {

    var gridData = [];


    if (tab == 1) {
      gridData = [...announcementData];
    } else if (tab == 2) {
      gridData = [...recordedCommercial];
    } else if (tab == 3) {
      gridData = [...showData];
    } else {
      gridData = [...showInHouseData]
    }

    if (gridData.length == 0) {
      toast.info(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    var storeData = [];
    if (tab != 3) {
      storeData = gridData.map(x => {
        return {
          Title: x.MediaEpisode.Title,
          AssetId: x.MediaEpisode.AssetId,
          Description: x.MediaEpisode.Description,
          Duration: utility.convertMilisecondsToStringWithFrames(x.MediaEpisode.Duration),
          ActualOnAir: utility.convertMilisecondsToStringWithFrames(x.ActualOnAir),
          NominalOnAir: utility.convertMilisecondsToStringWithFrames(x.NominalOnAir),
          Status: x.NominalOnAir > 0 && x.ActualOnAir > 0 ? "Reconciled" : x.NominalOnAir > 0 ? "Scheduled" : "None",
          Remarks: x.Comment ? x.Comment : '',
        }
      })
    } else {
      storeData = gridData.map(x => {
        return {
          Title: x.mediaEpisode.Title,
          AssetId: x.mediaEpisode.AssetId,
          Duration: utility.convertMilisecondsToStringWithFrames(x.mediaEpisode.Duration),
          SlotTime: utility.convertMilisecondsToStringWithFrames(x.SlotDateTime),
          Remarks: x.Comment ? x.Comment : '',
        }
      })
    }
    console.log(storeData)
    var res = await API.exportCSV(storeData);
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  return (
    <>
      <div>
        {showTitle && (
          <>
            <h1 className="page-title txt-color-bludeDark">
              <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
              ></i>
              <span>{myTitle === "" ? props.entityname : myTitle}</span>
            </h1>
          </>
        )}
        <div className="flex-container">
          <div className="martb mar">
            {showBack && (
              <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
            )}
            <RoundButton icon={"download"} btnColor={"info"} style={{ color: 'white' }} title={lang.export_button_tooltip} onClick={downloadExcel} />
          </div>

          <div className="flex-container-reverse flex-item-auto mb-2 mr-2">
            <div className="mt-1" style={{ paddingTop: "12px" }}>
              <ActionButton
                title={lang.show_button_tooltip}
                name={lang.show_button_text}
                onClick={showFilterData}
              />
            </div>
            <div className="ml-2" style={{ marginTop: "-7px" }}>
              <label htmlFor="">{lang.date_label} *</label>
              <input
                name={"Date"}
                className="form-control form-control-sm"
                type={"date"}
                value={dataItem.Date}
                onChange={onChange}
              />
            </div>

            <div className="col-2" style={{ marginTop: "-7px" }}>
              <label htmlFor="TabView">{lang.channel_label} *</label>
              <DropDownList
                style={{
                  backgroundColor: "white",
                }}
                data={channels}
                name="Channel"
                textField="FullChannelName"
                dataItemKey="_id"
                value={dataItem.Channel}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="ml-1">
            <i
              style={{ fontSize: "20px", color: "GrayText" }}
              className="fas fa-spinner fa-pulse"
            ></i>
          </div>
        )}

        {/* Excel Export */}

        <Box sx={{ width: "97%", typography: "body1" }}>
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList variant="scrollable"
                onChange={(event, newValue) => {
                  setTab(`${newValue}`);
                }}
                aria-label="lab API tabs example"
              >
                <Tab label={lang.announcements_label} value="1" />
                <Tab label={lang.recorded_commercial_label} value="2" />
                <Tab label={lang.schedule_label} value="3" />
                <Tab label={lang.in_house_label} value="4" />
              </TabList>
            </Box>
            <TabPanel value={"1"}>
              {isNoRecords && (
                <div style={{ margin: "0px 500px" }}>
                  <h4>
                    <strong>{lang.no_records_found}</strong>
                  </h4>
                </div>
              )}
              {!isLoading && !isNoRecords && (
                <Grid
                  style={{ height: "76vh" }}
                  data={orderBy(announcementData, sortAnnouncementData)}
                  sort={sortAnnouncementData}
                  sortable={true}
                  resizable={true}
                  onSortChange={(e) => { setSortAnnouncementData(e.sort) }}
                  rowRender={rowRender}
                  total={total}
                >
                  <GridColumn cell={MyCommandCell} width={"60px"} />
                  <GridColumn field="MediaEpisode.AssetId" title={lang.id_column} sortable={false} />
                  <GridColumn field="MediaEpisode.Title" title={lang.read_outs_column} sortable={false} />
                  <GridColumn field="MediaEpisode.Description" title={lang.scripts_column} width={'400px'} sortable={false} />
                  <GridColumn field="MediaEpisode.Duration" title={lang.duration_column} cell={TimeCell} sortable={false} />
                  <GridColumn field="ActualOnAir" title={lang.actual_on_air_column} cell={TimeCell} sortable={false} />
                  <GridColumn field="NominalOnAir" title={lang.nominal_on_air_column} cell={TimeCell} sortable={true} />
                  <GridColumn field="BookingStatusSID.Description" title={lang.booking_status_column} cell={StatusCell} sortable={false} />
                  <GridColumn field="Comment" title={lang.remark_column} sortable={false} />
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={"2"}>
              {isNoRecords && (
                <div style={{ margin: "0px 500px" }}>
                  <h4>
                    <strong>{lang.no_records_found}</strong>
                  </h4>
                </div>
              )}
              {!isLoading && !isNoRecords && (
                <Grid
                  style={{ height: "76vh" }}
                  data={orderBy(recordedCommercial, sortRecordedData)}
                  sort={sortRecordedData}
                  sortable={true}
                  resizable={true}
                  onSortChange={(e) => { setSortRecordedData(e.sort) }}
                  rowRender={rowRender}
                  total={total}
                >
                  <GridColumn cell={MyCommandCell} width={"60px"} />
                  <GridColumn field="MediaEpisode.Title" title={lang.title_column} sortable={false} />
                  <GridColumn field="MediaEpisode.AssetId" title={lang.asset_id_column} sortable={false} />
                  <GridColumn field="MediaEpisode.Duration" title={lang.duration_column} cell={TimeCell} sortable={false} />
                  <GridColumn field="ActualOnAir" title={lang.actual_on_air_column} cell={TimeCell} sortable={false} />
                  <GridColumn field="NominalOnAir" title={lang.nominal_on_air_column} cell={TimeCell} sortable={true} />
                  <GridColumn field="BookingStatusSID.Description" title={lang.booking_status_column} cell={StatusCell} sortable={false} />
                  <GridColumn field="Comment" title={lang.remark_column} sortable={false} />
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={"3"}>
              {isNoRecords && (
                <div style={{ margin: "0px 500px" }}>
                  <h4>
                    <strong>{lang.no_records_found}</strong>
                  </h4>
                </div>
              )}
              {!isLoading && !isNoRecords && (
                <Grid
                  style={{ height: "76vh" }}
                  data={orderBy(showData, sortShowData)}
                  sort={sortShowData}
                  sortable={true}
                  resizable={true}
                  onSortChange={(e) => { setSortShowData(e.sort) }}
                  total={total}
                >
                  <GridColumn cell={ShowCommandCell} width={"60px"} />
                  <GridColumn field="mediaEpisode.Title" title={lang.title_column} sortable={false} />
                  <GridColumn field="mediaEpisode.AssetId" title={lang.asset_id_column} sortable={false} />
                  <GridColumn field="mediaEpisode.Duration" title={lang.duration_column} cell={TimeCell} sortable={false} />
                  <GridColumn field="SlotDateTime" title={lang.slot_time_label} cell={TimeCell} sortable={true} />
                  {/* <GridColumn field="NominalOnAir" title="NominalOnAir" cell={TimeCell} /> */}
                  {/* <GridColumn field="BookingStatusSID.Description" title="Booking Status" cell={StatusCell} /> */}
                  <GridColumn field="Comment" title={lang.remark_column} sortable={false} />
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={"4"}>
              {isNoRecords && (
                <div style={{ margin: "0px 500px" }}>
                  <h4>
                    <strong>{lang.no_records_found}</strong>
                  </h4>
                </div>
              )}
              {!isLoading && !isNoRecords && (
                <Grid
                  style={{ height: "76vh" }}
                  data={orderBy(showInHouseData, sortShowData)}
                  sort={sortShowData}
                  sortable={true}
                  resizable={true}
                  onSortChange={(e) => { setSortShowData(e.sort) }}
                  total={total}
                >
                  <GridColumn cell={ShowCommandCell} width={"60px"} />
                  <GridColumn field="mediaEpisode.Title" title={lang.title_column} sortable={false} />
                  <GridColumn field="mediaEpisode.AssetId" title={lang.asset_id_column} sortable={false} />
                  <GridColumn field="mediaEpisode.Duration" title={lang.duration_column} cell={TimeCell} sortable={false} />
                  <GridColumn field="SlotDateTime" title={lang.slot_time_label} cell={TimeCell} sortable={true} />
                  <GridColumn field="Comment" title={lang.remark_column} sortable={false} />
                </Grid>
              )}
            </TabPanel>
          </TabContext>
        </Box>
        {showEditForm && <BossDialog
          title={lang.edit_header_dialog}
          onClose={() => { setShowEditForm(false); setEditDataItem(blankDataItem); }}
          width={"25vw"}
        >
          <div className='row mt-2'>
            <div className='col-12'>
              <EditPageHeader title={""} onSubmit={isHeaderEditPopup ? onSubmitHeaderComment : handleSubmit} onCancel={() => { setShowEditForm(false); setEditDataItem(blankDataItem); }} showTitle={false} />
              <div className='row mt-3'>
                {tab == 1 && <div className='col-12'>
                  <input type="checkbox" name={"Reconcile"} value={editDataItem.Reconcile} checked={editDataItem.Reconcile} onChange={onChangeEdit} />
                  <label className="ml-1">{lang.reconcile_label}</label>
                </div>}
                {tab == 1 && editDataItem.Reconcile && <div className='col-12 mt-3'>
                  <label>{lang.actual_on_air_label} *</label>
                  <TimePickerWithFormat
                    name="ActualOnAir"
                    value={editDataItem.ActualOnAir}
                    onChange={onChangeEdit}
                  />
                </div>}
                {tab == 1 && editDataItem.Reconcile && <div className='col-12 mt-3'>
                  <label>{lang.actual_duration_label} *</label>
                  <TimePickerWithFormat
                    name="ActualDuration"
                    value={editDataItem.ActualDuration}
                    onChange={onChangeEdit}
                  />
                </div>}
                <div className='col-12 mt-2'>
                  <label>{lang.comment_label}</label>
                  <textarea
                    name="Comment"
                    type="text"
                    className="form-control form-control-sm"
                    onChange={onChangeEdit}
                    value={editDataItem.Comment}
                  />
                </div>
              </div>
            </div>
          </div>
        </BossDialog>
        }
      </div>
    </>
  );
};
