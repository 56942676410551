/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";

export const ProtoTypeContentEditForm = (props) => {

    const blankDataItem = {
        SID: props.item.SID,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        Prefix: props.item?.Prefix ?? "",
        NextNumber: props.item?.NextNumber ?? "",
        Length: props.item?.Length ?? "",
        Archive: props.item?.Archive ?? false,
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
    }

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (dataItem.Description == "" || dataItem.Description == undefined) {
            toast.error(`${lang.please_enter_discount_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Prefix == "" || dataItem.Prefix == undefined) {
            toast.error(`${lang.please_enter_prefix_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.NextNumber == "" || dataItem.NextNumber == undefined) {
            toast.error(`${lang.please_enter_next_number_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.Length == "" || dataItem.Length == undefined) {
            toast.error(`${lang.please_enter_max_length_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;
    }

    const handleSubmit = async () => {

        if (!isValid()) return;
        var saveData = {
            // ...dataItem,
            SID: dataItem.SID,
            Description: dataItem.Description,
            Prefix: dataItem.Prefix,
            NextNumber: parseInt(dataItem.NextNumber),
            Length: parseInt(dataItem.Length),
            Archive: dataItem.Archive ?? false,
        }
        console.log(saveData);
        var res = await API.saveData(ENTITYNAME.ProtoTypeContent, saveData);
        console.log(res.data);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.PROTO_TYPE_CONTENT, data: res.data, message: res.message };
            API.SaveLogs(logData);
            props.cancelEdit();
            utility.deleteLocalStorageItem(ENTITYNAME.ProtoTypeContent);
            props.refresh();
            return;
        } else {
            toast.error(res.message);
        }
    }

    return (
        <>
            <BossDialog
                title={props.item?.Description ? props.item?.Description : lang.create_prototypecontent_dialog_header}
                onClose={props.cancelEdit}
                width={"630px"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.description_label} *</label>
                                <input
                                    name={"Description"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Description}
                                />
                            </div>
                            <div className="col-6">
                                <label>{lang.prefix_label} *</label>
                                <input
                                    name={"Prefix"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Prefix}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.next_number_label} *</label>
                                <input
                                    name={"NextNumber"}
                                    type="number"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.NextNumber}
                                />
                            </div>
                            <div className="col-6">
                                <label>{lang.max_length_label} *</label>
                                <input
                                    name={"Length"}
                                    type="number"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Length}
                                />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col">
                                <input
                                    type="checkbox"
                                    name={"Archive"}
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive}
                                    onChange={onChange}
                                />
                                <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog>
        </>
    );
}