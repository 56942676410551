import BossDialog from "../../components/BossDialog";
import { COMPANYTYPE, ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useState } from "react";
import { toast } from "react-toastify";
import { utility } from "../../framework/utility/utilityProvider";
import * as API from "../../framework/API/api_digitalSign";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import * as APIRES from "../../framework/API/api"

export const ScreenGroupEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        ...props?.item,
        SID: props?.item?.SID ?? 0,
        Name: props?.item?.copy ? 'copy of ' + props?.item?.Name : props?.item?.Name ?? '',
        Description: props?.item?.Description ?? '',
        Archive: props?.item?.Archive ?? false
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
    const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
    const [selectedCompany, setSelectedCompany] = useState(props?.item?.SID > 0 ? companies.find(x => x?._id == props?.item?.Company_id) : {});

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (isEnterpriseLogin && (!selectedCompany || Object.keys(selectedCompany).length == 0)) {
            toast.info(lang.please_select_company_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {

        if (isValid()) {
            const saveData = {
                _id: dataItem._id,
                SID: dataItem.SID ?? 0,
                Name: dataItem.Name,
                Description: dataItem.Description,
                Company_id: isEnterpriseLogin ? selectedCompany?._id : company?._id,
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Name", "=", dataItem.Name]],
            }

            let res = await API.saveData(ENTITYNAME.DigitalSignScreenGroup, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.DIGITAL_SIGN_SCREEN_GROUP, data: res.data, message: res.message };
                APIRES.SaveLogs(logData);
                utility.deleteLocalStorageItem(ENTITYNAME.DigitalSignScreenGroup);
                props?.cancelEdit();
                props?.refresh && props?.refresh();
                props?.isInsertGroupScreen && props?.handleSelectedGroup([res?.data]);
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const onChange = (e) => {

        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else if (e.target.name == "Name") {
            setDataItem({ ...dataItem, Name: e.target.value, Description: e.target.value });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }

    }

    return (
        <BossDialog
            title={props?.item?.Name ?? lang.create_screen_group_dialog_header}
            onClose={props.cancelEdit}
            width={"400px"}
        >
            <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
            <div className="row mt-2">
                <div className="col-12">
                    <label>{lang.name_label} *</label>
                    <input
                        name={"Name"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.Name}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    <label>{lang.description_label}</label>
                    <input
                        name={"Description"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.Description}
                    />
                </div>
            </div>
            {isEnterpriseLogin && <div className="row mt-2">
                <div className="col-12">
                    <label htmlFor="TabView">{lang.company_label} *</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={companies}
                        name="Company"
                        textField="Name"
                        dataItemKey="_id"
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.target.value)}
                    />
                </div>
            </div>}
            <div className="row mt-3">
                <div className="col" >
                    <input type={"checkbox"} name={"Archive"}
                        onChange={(e) => onChange(e)}
                        value={dataItem.Archive}
                        checked={dataItem.Archive} />
                    <label className="ml-1">{lang.archive}</label>
                </div>
            </div>
        </BossDialog>)
};