/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { ImageBrowser } from "../forms/helpercomponents/selectFile/ImageBrowser";
import { DEVICE_ACCESS_CONTROL, ENTITYNAME, LOGEVENT, LOGOPOSITION, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../constant/constant";
import * as API from '../API/api';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RadioButton } from "@progress/kendo-react-inputs";
import Preview from "../../modules/platformLayout/tv/homePage/components/Preview";
import { utility } from "../utility/utilityProvider";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OttSettingPlatformForm from "./OttSettingPlatformForm";
import { Switch } from "@mui/material";
import socket from "../socket/socket";
import moment from "moment";
import RegionEditForm from "../../modules/masters/RegionEditForm";
import { TimePickerWithFormat } from "../forms/helpercomponents/timepicker/TimePickerWithFormat";

export const OttSettingForm = (props) => {

    const { SID } = useParams();
    const lang = useTranslation();
    const navigate = useNavigate();

    let blankDataIteam = {
        SID: SID,
        applicationName: "",
        logo: "",
        // piPageTemplate: {},
        showBottomMenu: false,
        homePage: {},
        showHamburgerMenu: false,
        hamburgerMenu: {},
        theme: {},
        adLog: "",
        imageCDNBaseURL: "",
        videoCDNBaseURL: "",
        logoSize: 30,
        applicationColor: "",
        showProviderIcon: false,
        nextPublishDate: "",
        region: {},
        concurrency: "00:00:00:00",
        forceUpgrade: false,
        version: "",
        deviceAccessControl: DEVICE_ACCESS_CONTROL.Login

    }

    const [dataItem, setDataItem] = useState(blankDataIteam);
    const [themes, _setThemes] = useState([]);
    const [imageFiles, setImageFiles] = useState(new Set());
    const [platformImageFiles, setPlatformImageFile] = useState({});
    const [selectedOption, setSelectedOption] = useState("logoPostionLeft");
    const [tabNumber, setTabNumber] = React.useState("0");
    const [platforms, setPlatforms] = useState([]);
    const [platformDetails, setPlatformDetails] = useState([]);
    const [regions, setRegions] = useState([]);
    const [showCreateNewRegionForm, setShowCreateNewRegionForm] = useState(false);

    let pageRef = useRef([]);
    let menusRef = useRef([]);
    let themesRef = useRef([]);
    const [pages, _setPages] = useState([]);
    const [menus, _setMenus] = useState([]);
    const setPages = (data) => {
        _setPages(data);
        pageRef.current = data;
    }
    const setMenus = (data) => {
        _setMenus(data);
        menusRef.current = data;
    }

    const setThemes = (data) => {
        _setThemes(data);
        themesRef.current = data;
    }

    useEffect(() => {
        loadcombo();
    }, [])

    const loadcombo = async () => {

        let themeRes = await API.getDataLookup(ENTITYNAME.Theme, { query: ["Archive", '!=', true] });
        console.log(themeRes.data);
        setThemes(themeRes.data);

        let homePageRes = await API.getDataLookup(ENTITYNAME.Page, { sort: { Name: 1 }, query: ["Archive", '=', false] });
        setPages(homePageRes.data);

        let menu = await API.getDataLookup(ENTITYNAME.OttMenu, { sort: { Name: 1 }, query: ["Archive", '=', false] });
        setMenus(menu.data);

        let platformRes = await API.getDataLookup(ENTITYNAME.Platform, { sort: { Description: 1 }, query: ["Archive", '=', false] });
        platformRes.data.map(x => blankDataIteam[`fromDefault${x.SID}`] = true);
        setPlatforms(platformRes.data);

        let regionRes = await API.getDataLookup(ENTITYNAME.Region, { sort: { Description: 1 }, query: ["Archive", '=', false] });
        setRegions(regionRes.data);

        loadEditData();
    }

    const loadEditData = async () => {

        let res = await API.getData(ENTITYNAME.OttSetting);
        console.log(res.data);
        if (res.success) {
            let data = {
                ...dataItem,
                ...res.data[0],
                logo: res.data[0]?.logo ?? '',
                homePage: res.data[0]?.homePage != "" ? pageRef.current?.find(item => item?._id == res.data[0]?.homePage) : {},
                hamburgerMenu: res.data[0]?.hamburgerMenu != "" ? menusRef.current?.find(item => item?._id == res.data[0]?.hamburgerMenu) : {},
                logoPostion: res.data[0]?.logoPostion == LOGOPOSITION.Left ? setSelectedOption("logoPostionLeft") : res.data[0]?.logoPostion == LOGOPOSITION.Center ? setSelectedOption("logoPostionCenter") : setSelectedOption("logoPostionRight"),
                theme: themesRef.current?.find(x => x._id == res.data[0]?.theme),
                nextPublishDate: res.data[0]?.nextPublishDate ? moment(res.data[0]?.nextPublishDate).format("YYYY-MM-DD") : "",
                region: res.data[0]?.region ?? regions[0] ?? {},
                concurrency: res.data[0]?.concurrency ? utility.convertMilisecondsToStringWithFrames(res.data[0]?.concurrency) : "00:00:00:00",
                deviceAccessControl: res.data[0]?.deviceAccessControl ?? DEVICE_ACCESS_CONTROL.Login
            }
            // Get Only Platform Data
            const platformData = res.data.slice(1);
            setPlatformDetails(platformData.map(item => {
                data[`fromDefault${item.platform}`] = false;
                return {
                    key: item.platform,
                    value: {
                        ...item,
                        logo: item?.logo ?? '',
                        homePage: item?.homePage != "" ? pageRef.current?.find(x => x?._id == item?.homePage) : {},
                        hamburgerMenu: item?.hamburgerMenu != "" ? menusRef.current?.find(y => y?._id == item?.hamburgerMenu) : {},
                        logoPostion: item?.logoPostion == LOGOPOSITION.Left ? "logoPostionLeft" : item?.logoPostion == LOGOPOSITION.Center ? "logoPostionCenter" : "logoPostionRight",
                        theme: themesRef.current?.find(x => x._id == item?.theme),
                    }
                }
            }));
            setDataItem(data);
        }
    }

    const onChange = (e) => {

        if (e.target.name == "showBottomMenu") {
            setDataItem({ ...dataItem, showBottomMenu: !dataItem.showBottomMenu });
        }
        else if (e.target.name.includes("fromDefault")) {
            let key = parseInt(e.target.name.replace("fromDefault", ""));
            if (dataItem[e.target.name]) {
                const existingItem = platformDetails.some(item => item.key === key);
                if (!existingItem) {
                    let defaultItem = {
                        key: key,
                        value: {
                            logo: dataItem.logo,
                            showBottomMenu: dataItem.showBottomMenu,
                            homePage: dataItem.homePage,
                            showHamburgerMenu: dataItem.showHamburgerMenu,
                            hamburgerMenu: dataItem.hamburgerMenu,
                            logoSize: dataItem.logoSize,
                            theme: dataItem.theme,
                            applicationColor: dataItem.applicationColor,
                            forceUpgrade: dataItem.forceUpgrade,
                            version: dataItem.version
                        }
                    }
                    setPlatformDetails([...platformDetails, defaultItem]);
                }
            }
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        }
        else if (e.target.name == "showHamburgerMenu") {
            setDataItem({ ...dataItem, showHamburgerMenu: !dataItem.showHamburgerMenu });
        }
        else if (e.target.name == "logoPostionLeft" || e.target.name == "logoPostionCenter" || e.target.name == "logoPostionRight") {
            setSelectedOption(e.target.name);
        }
        else if (e.target.name == 'logo' && e.target?.files) {
            if (e.target.files != undefined) {
                setImageFiles(e.target.files[0]);
                setDataItem({ ...dataItem, logo: e.target.files[0] ? URL.createObjectURL(e.target.files[0]) : "" });
            } else {
                setImageFiles(new Set());
                setDataItem({ ...dataItem, logo: "" });
            }
        }
        else if (e.target.name == 'showProviderIcon') {
            setDataItem({ ...dataItem, showProviderIcon: !dataItem.showProviderIcon });
        }
        else if (e.target.name == 'forceUpgrade') {
            setDataItem({ ...dataItem, forceUpgrade: !dataItem.forceUpgrade });
        }
        else if (e.target.name == "deviceAccessControl") {
            setDataItem({ ...dataItem, deviceAccessControl: e.target.value.id });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }

    }

    const handleLogoImageSelect = (dataList) => {
        setDataItem({ ...dataItem, logo: dataList[0].ImageUrl });
    }

    const isValid = () => {
        if (dataItem.region == undefined || dataItem.region == "" || dataItem.region == {}) {
            toast.error(`Please Select Deafult Region`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.applicationName == undefined || dataItem.applicationName == "") {
            toast.error(`${lang.please_enter_application_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.logo == undefined || dataItem.logo == "") {
            toast.error(`${lang.please_select_logo_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (selectedOption == "") {
            toast.error(`${lang.please_select_logo_position_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.homePage).length == 0) {
            toast.error(`${lang.please_select_home_page_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.theme).length == 0) {
            toast.error(`${lang.please_select_theme_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.imageCDNBaseURL == undefined || dataItem.imageCDNBaseURL == "") {
            toast.error(`${lang.please_enter_image_cdn_base_url_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.videoCDNBaseURL == undefined || dataItem.videoCDNBaseURL == "") {
            toast.error(`${lang.please_enter_video_cdn_base_url_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.showHamburgerMenu && (!dataItem.hamburgerMenu || Object.keys(dataItem.hamburgerMenu).length == 0)) {
            toast.error(`${lang.please_select_hamburger_menu_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.forceUpgrade && (dataItem.version === undefined || dataItem.version === "")) {
            toast.error(lang.please_enter_version_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmitPlatform = async () => {

        for (const [index, element] of platformDetails.entries()) {
            let value = element.value;
            let key = parseInt(element.key);
            const fromDefault = dataItem[`fromDefault${key}`] ?? true;

            if (fromDefault) {
                await API.deleteDataByQuerry(ENTITYNAME.OttSetting, ["platform", "=", key]);
                let logData = { event: LOGEVENT.DELETE_OTT_SETTING_PLATFORM, module: MODULE.OTTSETTING, data: { ...value, platform: key }, message: "Delete Platforms" };
                API.SaveLogs(logData);
            } else {
                let imageUrl = value.logo;
                if (platformImageFiles.key == key && platformImageFiles?.value.size > 0) {
                    let resUrl = await API.uploadImage(platformImageFiles?.value);
                    if (!resUrl.success) {
                        toast.error(`${lang.upload_fail_error_message}`, {
                            position: toast.POSITION.TOP_RIGHT
                        });
                    }
                    imageUrl = resUrl.data;
                    value.ImageUrl = imageUrl;
                }

                let saveData = {
                    SID: value.SID ?? 0,
                    applicationName: dataItem.applicationName,
                    logo: imageUrl ?? "",
                    logoPostion: value.logoPostion == "logoPostionLeft" ? LOGOPOSITION.Left : value.logoPostion == "logoPostionCenter" ? LOGOPOSITION.Center : LOGOPOSITION.Right,
                    showBottomMenu: value.showBottomMenu ?? false,
                    homePage: value.homePage?._id,
                    showHamburgerMenu: value.showHamburgerMenu ?? false,
                    hamburgerMenu: value.showHamburgerMenu ? value.hamburgerMenu?._id : '',
                    theme: value?.theme?._id,
                    adTag: dataItem.adTag,
                    imageCDNBaseURL: dataItem.imageCDNBaseURL,
                    videoCDNBaseURL: dataItem.videoCDNBaseURL,
                    logoSize: value.logoSize == "" ? parseFloat(30) : parseFloat(value.logoSize),
                    applicationColor: value.applicationColor == "" ? "#0000FF" : value.applicationColor,
                    platform: key,
                    showProviderIcon: value.showProviderIcon ?? false,
                    concurrency: value?.concurrency && utility.convertStringWithFramesToMilliseconds(value?.concurrency),
                    deviceAccessControl: value?.deviceAccessControl ?? DEVICE_ACCESS_CONTROL.Login,
                    forceUpgrade: value?.forceUpgrade ?? false,
                    version: value?.forceUpgrade ? value?.version : ''
                }
                console.log(saveData);
                let res = await API.saveData(ENTITYNAME.OttSetting, saveData);
                if (saveData.SID == 0) {
                    //SET UPDATED DATE TO PLATFORM DETAILS
                    let copyPlatformDetails = [...platformDetails];
                    copyPlatformDetails.splice(index, 1, { key: key, value: res.data })
                    console.log(copyPlatformDetails)
                    setPlatformDetails(copyPlatformDetails);
                }
                let logData = { event: LOGEVENT.UPDATE_OTT_SETTING_PLATFROM, module: MODULE.OTTSETTING, data: res.data, message: res.message };
                API.SaveLogs(logData);
                if (!res.success) {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }
        }
    }

    const handleSubmit = async () => {

        if (!isValid()) return;

        if (platformDetails.length > 0) {
            await handleSubmitPlatform();
        }

        let imageUrl = dataItem.logo;
        if (imageFiles.size > 0) {
            let resUrl = await API.uploadImage(imageFiles);
            if (!resUrl.success) {
                toast.error(`${lang.upload_fail_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
            imageUrl = resUrl.data;
            dataItem.ImageUrl = imageUrl;
        }

        let saveData = {
            _id: dataItem?._id,
            SID: dataItem.SID,
            applicationName: dataItem.applicationName,
            logo: imageUrl,
            logoPostion: selectedOption == "logoPostionLeft" ? LOGOPOSITION.Left : selectedOption == "logoPostionCenter" ? LOGOPOSITION.Center : LOGOPOSITION.Right,
            // piPageTemplate: dataItem.piPageTemplate,
            showBottomMenu: dataItem.showBottomMenu,
            homePage: dataItem.homePage?._id,
            showHamburgerMenu: dataItem.showHamburgerMenu,
            hamburgerMenu: dataItem.showHamburgerMenu ? dataItem.hamburgerMenu?._id : '',
            theme: dataItem?.theme?._id,
            adTag: dataItem.adTag,
            imageCDNBaseURL: dataItem.imageCDNBaseURL,
            videoCDNBaseURL: dataItem.videoCDNBaseURL,
            logoSize: dataItem.logoSize == "" ? parseFloat(30) : parseFloat(dataItem.logoSize),
            applicationColor: dataItem.applicationColor == "" ? "#0000FF" : dataItem.applicationColor,
            showProviderIcon: dataItem.showProviderIcon ?? false,
            nextPublishDate: new Date(dataItem.nextPublishDate)?.getTime(),
            region: dataItem.region,
            concurrency: dataItem?.concurrency && utility.convertStringWithFramesToMilliseconds(dataItem?.concurrency),
            deviceAccessControl: dataItem?.deviceAccessControl ?? DEVICE_ACCESS_CONTROL.Login,
            forceUpgrade: dataItem?.forceUpgrade ?? false,
            version: dataItem?.forceUpgrade ? dataItem?.version : ''
        }
        console.log(saveData);
        let res = await API.saveData(ENTITYNAME.OttSetting, saveData);
        console.log(res.data);
        if (!res.success) {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        socket.emit(SOCKET_EVENTS.onSocketData, {
            action: SOCKET_ACTION.PLATFORM_THEME_REFRESH,
            module: MODULE.PLATFORM,
            data: "Theme updated",
            // user: { name: userData.name, _id: userData._id }
        });
        utility.deleteLocalStorageItem(ENTITYNAME.OttSetting);
        let logData = { event: LOGEVENT.UPDATE_OTT_SETTING, module: MODULE.OTTSETTING, data: res.data, message: res.message };
        API.SaveLogs(logData);
        loadEditData();
        toast.success(lang.update_successfully_label, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const handleTabChange = (event, newValue) => {
        setTabNumber(`${newValue}`);
    };

    const handlePlatformDetails = (data) => {
        let index = platformDetails.findIndex((y) => y.key === data.key);
        if (index > -1) {
            let tempData = [...platformDetails];
            tempData[index] = data;
            setPlatformDetails(tempData);
        } else {
            setPlatformDetails([...platformDetails, data]);
        }
    };

    const handleNextWindowRoute = (path) => {
        window.open(path, "_blank") //to open new tab
    }

    const refreshTheme = () => {
        loadcombo();
    }

    return (
        <div className="row m-1" >
            <EditPageHeader title={lang.settings_label} onSubmit={handleSubmit} onCancel={() => navigate(-1)} />
            <div className="col-6 mb-2 ">
                <div className="row mt-3">
                    <div className="col-6">
                        <label>{lang.application_name_label} *</label>
                    </div>
                    <div className="col-6">
                        <input
                            name={"applicationName"}
                            type="text"
                            className="form-control form-control-sm"
                            onChange={onChange}
                            value={dataItem.applicationName}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <label>{lang.ad_tag_label}</label>
                    </div>
                    <div className="col-6">
                        <input
                            name={"adTag"}
                            type="text"
                            className="form-control form-control-sm"
                            onChange={onChange}
                            value={dataItem.adTag}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <label>{lang.image_cdn_base_url_label} *</label>
                    </div>
                    <div className="col-6">
                        <input
                            name={"imageCDNBaseURL"}
                            type="text"
                            className="form-control form-control-sm"
                            onChange={onChange}
                            value={dataItem.imageCDNBaseURL}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <label>{lang.video_cdn_base_url_label} *</label>
                    </div>
                    <div className="col-6">
                        <input
                            name={"videoCDNBaseURL"}
                            type="text"
                            className="form-control form-control-sm"
                            onChange={onChange}
                            value={dataItem.videoCDNBaseURL}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <label>Default Region *</label>
                    </div>

                    <div className="col-6" style={{ display: "flex" }}>
                        <DropDownList
                            style={{ backgroundColor: 'white' }}
                            data={regions}
                            name="region"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.region}
                            onChange={onChange}
                        />
                        <button type="button" title={'Create new region'} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { setShowCreateNewRegionForm(true) }}><i className="fa-solid fa-plus fa-xs"></i>
                        </button>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <label>{lang.next_publish_date_label}</label>
                    </div>
                    <div className="col-3">
                        <input
                            name={"nextPublishDate"}
                            type="date"
                            className="form-control form-control-sm"
                            onChange={onChange}
                            value={dataItem.nextPublishDate}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <label>Device Access Control</label>
                    </div>

                    <div className="col-6">
                        <DropDownList
                            style={{ backgroundColor: 'white' }}
                            data={Object.keys(DEVICE_ACCESS_CONTROL).map(key => ({ id: DEVICE_ACCESS_CONTROL[key], Description: key }))}
                            name="deviceAccessControl"
                            textField="Description"
                            dataItemKey="id"
                            value={{ id: dataItem.deviceAccessControl, Description: Object.keys(DEVICE_ACCESS_CONTROL).find(key => DEVICE_ACCESS_CONTROL[key] === dataItem.deviceAccessControl) }}
                            onChange={onChange}
                        />
                    </div>
                </div>
                {dataItem.deviceAccessControl === DEVICE_ACCESS_CONTROL.Playback && <div className="row mt-3">
                    <div className="col-6" >
                        <label htmlFor="">{lang.concurrency_label} </label>
                    </div>
                    <div className="col-6">
                        <label style={{ color: "gray", fontSize: "12px", letterSpacing: "0.5px" }}>{lang.check_every_label}</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="concurrency"
                            value={dataItem.concurrency}
                            onChange={onChange}
                        />
                    </div>
                </div>
                }
                <div className="row mt-3 mb-5">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={tabNumber}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} aria-label="tabs example">
                                    <Tab label={lang.default_label} value="0" />
                                    {platforms.map(x => {
                                        return <Tab label={x.Description} value={x.SID.toString()} />
                                    })}
                                </TabList>
                            </Box>

                            <TabPanel value={"0"} style={{ padding: "10px" }}>

                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label >{lang.application_color_label}</label>
                                    </div>
                                    <div className="col">
                                        <input
                                            type="color"
                                            name="applicationColor"
                                            className="cursor-pointer"
                                            value={dataItem.applicationColor}
                                            onChange={onChange}
                                        />
                                        <span className="ml-2">{dataItem?.applicationColor}</span>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label>{lang.logo_label} *</label>
                                    </div>
                                    <div className="col-6">
                                        <ImageBrowser wherestatement={['OttPosterTypeSID', '=', 4]} name={'logo'} value={dataItem.logo} onChange={onChange} setSelectedImage={handleLogoImageSelect} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <label >{lang.logo_size_label}</label>
                                    </div>
                                    <div className="col-2">
                                        <input
                                            name={"logoSize"}
                                            type="number"
                                            className="form-control form-control-sm"
                                            onChange={onChange}
                                            value={dataItem.logoSize}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6"><label>{lang.logo_position_label} *</label></div>
                                    <div className="col-6">
                                        <RadioButton
                                            name="logoPostionLeft"
                                            checked={selectedOption == "logoPostionLeft"}
                                            label="Left"
                                            onClick={onChange}
                                        />
                                        <RadioButton
                                            name="logoPostionCenter"
                                            className="ml-4"
                                            checked={selectedOption == "logoPostionCenter"}
                                            label="Center"
                                            onClick={onChange}
                                        />
                                        <RadioButton
                                            name="logoPostionRight"
                                            checked={selectedOption == "logoPostionRight"}
                                            className="ml-4"
                                            label="Right"
                                            onClick={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6"><label>{lang.theme_label} *</label></div>
                                    <div className="col-4">
                                        <DropDownList
                                            data={themes}
                                            style={{ backgroundColor: 'white' }}
                                            name="theme"
                                            textField="Name"
                                            dataItemKey="_id"
                                            value={dataItem.theme}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <span className="col-1 mt-1">
                                        <i title={lang.open_theme_new_tab_label} className="fa fa-external-link mt-1 cursor-pointer" style={{ marginLeft: "-15px" }} onClick={(e) => { e.preventDefault(); handleNextWindowRoute(`/home/theme/${dataItem.theme.SID}`) }}></i>
                                    </span>
                                    <span className="col-1 mt-1">
                                        <i title={lang.refresh_theme_label} className="fa fa-arrows-rotate img-circle cursor-pointer" style={{ marginLeft: "-40px" }} onClick={refreshTheme}></i>
                                    </span>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6 mt-2"><label>{lang.show_provider_icon_label} *</label></div>
                                    <div className="col-6">
                                        <Switch name={"showProviderIcon"} checked={dataItem.showProviderIcon} value={dataItem.showProviderIcon} onChange={onChange} />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <label className="mr-2" htmlFor="TabView">{lang.show_bottom_menu_label}</label>
                                        <input type="checkbox" name={"showBottomMenu"} value={dataItem.showBottomMenu} checked={dataItem.showBottomMenu} onChange={onChange} />
                                    </div>
                                </div>
                                {!dataItem.showBottomMenu && <div className="row mt-4">
                                    <div className="col-6"><label>{lang.home_page_label} *</label></div>
                                    <div className="col-4">
                                        <DropDownList
                                            data={pages}
                                            style={{ backgroundColor: 'white' }}
                                            name="homePage"
                                            textField="Name"
                                            dataItemKey="_id"
                                            value={dataItem.homePage}
                                            onChange={onChange}
                                        />
                                    </div>
                                    <span className="col-1 mt-1">
                                        <i title={lang.open_theme_new_tab_label} className="fa fa-external-link" style={{ marginLeft: "-15px" }} onClick={(e) => { e.preventDefault(); handleNextWindowRoute(`/home/Page/${dataItem.homePage.SID}`) }}></i>
                                    </span>
                                    <span className="col-1 mt-1">
                                        <i title={lang.refresh_theme_label} className="fa fa-arrows-rotate fa-fw img-circle" style={{ marginLeft: "-40px" }} onClick={refreshTheme}></i>
                                    </span>
                                </div>}

                                <div className="row mt-2">
                                    <div className="col-6 mt-3">
                                        <label className="mr-2" htmlFor="TabView">{lang.show_hamburger_menu_label}</label>
                                        <input type="checkbox" name={"showHamburgerMenu"} value={dataItem.showHamburgerMenu} checked={dataItem.showHamburgerMenu} onChange={onChange} />
                                    </div>
                                    {dataItem.showHamburgerMenu && <div className="col-6">
                                        <label>{lang.hamburger_menu_label} *</label>
                                        <DropDownList
                                            data={menus}
                                            style={{ backgroundColor: 'white' }}
                                            name="hamburgerMenu"
                                            textField="Name"
                                            dataItemKey="_id"
                                            value={dataItem.hamburgerMenu}
                                            onChange={onChange}
                                        />
                                    </div>}
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6 mt-3">
                                        <label className="mr-2" htmlFor="TabView">{lang.force_upgrade_label}</label>
                                        <input type="checkbox" name={"forceUpgrade"} value={dataItem.forceUpgrade} checked={dataItem.forceUpgrade} onChange={onChange} />
                                    </div>
                                    {dataItem.forceUpgrade && <div className="col-6">
                                        <label>{lang.version_label} *</label>
                                        <input type="text" name="version" onChange={onChange} value={dataItem.version} className="form-control form-control-sm" />
                                    </div>}
                                </div>
                            </TabPanel>

                            {platforms.map(item => {
                                return <TabPanel value={item.SID.toString()} style={{ padding: "10px" }}>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            <label >{lang.from_default_label}</label>
                                            <Switch name={`fromDefault${item.SID}`} checked={dataItem[`fromDefault${item.SID}`]} onChange={onChange} />
                                        </div>
                                    </div>
                                    {!dataItem[`fromDefault${item.SID}`] && <>
                                        <OttSettingPlatformForm setPlatformImageFile={setPlatformImageFile} handlePlatformDetails={handlePlatformDetails} dataItem={platformDetails && platformDetails?.find(x => x?.key == item?.SID)} themeData={themes} pageData={pages} menuData={menus} platformSID={item.SID} refreshCombo={loadcombo} />
                                    </>}
                                </TabPanel>
                            })}
                        </TabContext>
                    </Box>
                </div>
            </div>
            <div className="col-1">
                {tabNumber == "0" && <img style={{ marginTop: '450px' }} src={dataItem?.logo} width="150px" />}
                {tabNumber != "0" && !dataItem[`fromDefault${tabNumber}`] && <img style={{ marginTop: '500px' }} src={platformDetails?.find(x => x?.key == tabNumber)?.value?.logo ?? ""} width="150px" />}
            </div>
            <div className="col-2 mt-4" style={{ height: '100%', scale: '0.7', color: 'white' }}>
                <div className="row">
                    <Preview bgColor="#F4F5FA" style={{ top: "5px", left: "50px", color: "black", zIndex: "99999", cursor: "pointer" }} isMobile={true} />
                </div>
            </div>
            {showCreateNewRegionForm && <RegionEditForm item={{ SID: 0 }} cancelEdit={() => {
                setShowCreateNewRegionForm(false)
            }} refresh={() => loadcombo()} />}
        </div >
    )
}