/* eslint-disable */
import { useEffect, useState } from "react";
import * as API from '../../../../../../src/framework/API/api'
import { utility } from "../../../../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import Page from "./Page";
import { useLocation } from "react-router-dom";
import { ENTITYNAME, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../../../../../framework/constant/constant";
import useSocket from "../../../../../framework/socket/useSocket";

const Preview = (props) => {
    console.log(props);

    const [page, setPage] = useState([]);
    const [ottSetting, setOttSetting] = useState({});
    const [bottomMenu, setBottomMenu] = useState([]);
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(props.isMobile);

    const location = useLocation();


    useEffect(() => {

        if (location.state && location.state.page) {
            setPage(location.state.page);
            setIsMobile(location.state.isMobile);
            setLoading(false);
        } else if (props.pageData) {
            setPage(props.pageData);
            setLoading(false);
        } else {
            setIsMobile(props.isMobile);
            loadData();
        }

    }, [props.isMobile, location?.state?.page])

    const socket = useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

        if (socketData.module != MODULE.PLATFORM) return;

        switch (socketData.action) {
            case SOCKET_ACTION.PLATFORM_THEME_REFRESH:
            case SOCKET_ACTION.PLATFORM_GRID_REFRESH:
            case SOCKET_ACTION.PLATFORM_PAGE_REFRESH:
            case SOCKET_ACTION.PLATFORM_MENU_REFRESH:
            case SOCKET_ACTION.PLATFORM_RAIL_REFRESH:
                loadOttSettings();
                break;
            default:
                break;
        }
    });

    const loadData = async () => {
        setLoading(true);
        // const response = await API.getLayout();
        const response = await API.getLayout3();
        if (response.success) {
            const pages = response.data.pages;
            await loadOttSettings();
            if (pages.length > 0) {
                setPages(pages);
                utility.setValue('pages', pages);
                //to set the first page as home page                
                var selectedPage = props.pageData ?? undefined;
                let page = selectedPage ? pages.filter((x) => x._id == selectedPage._id) : pages.filter(page => page.IsHomePage);
                if (ottSetting.showBottomMenu && bottomMenu.length > 0) {
                    page = pages.filter(p => p._id == bottomMenu[0]._id);
                    page = page.length > 0 ? page[0] : pages.filter(page => page.IsHomePage)[0];
                }
                if (page) {
                    setPage(() => page);
                } else {
                    toast.error("No Home Page Found");
                }
            }
        }
        setLoading(false);
    }

    const loadOttSettings = async () => {
        let res = await API.getOttSettings();
        console.log(res);
        if (res.success) {
            setOttSetting(res.data);
            if (res?.data?.showBottomMenu) {
                await loadBottomMenu();
            }
        }
    }

    const loadBottomMenu = async () => {
        let res = await API.getBottomMenu();
        console.log(res);
        if (res.success) {
            setBottomMenu(res.data.Pages ?? []);
        }
    }

    const changePage = (id) => {
        setPage(pages.filter(x => x._id == id)[0]);
    }

    return loading ?
        <div style={{ height: "95%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <i className="fa fa-spin fa-spinner fa-2xl" />
        </div>
        : <div style={{ position: "relative" }}>
            <i style={props?.style ?? { position: "sticky", top: "5px", left: "5px", color: "white", zIndex: "99999", cursor: "pointer" }} className="fa fa-refresh fa-xl" onClick={() => {
                loadData();
            }} />
            <Page bgColor={props?.bgColor} page={page} isMobile={isMobile} ottSetting={ottSetting} bottomMenu={bottomMenu} changePage={changePage} />
        </div>
}

export default Preview;