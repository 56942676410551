/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ANALYTICS_EVENTS, ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from "react-toastify";
import * as API from "../../framework/API/api";
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";

const AnalyticsEventEditForm = () => {

    const lang = useTranslation();
    const navigate = useNavigate();

    const [dataItem, setDataItem] = useState({});
    const [analyticsEvents, setAnalyticsEvents] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        loadcombo();
        loadEditData();
    }, []);

    const loadcombo = async () => {
        let analyticsEventRes = Object.keys(ANALYTICS_EVENTS).map(x => ({
            _id: ANALYTICS_EVENTS[x], Description: x
        }));
        setAnalyticsEvents(analyticsEventRes);
    };

    const loadEditData = async () => {
        let res = await API.getData(ENTITYNAME.AnalyticsEvents);
        if (res.success) setDataItem(res.data.length > 0 ? res.data[0] : {});
    };

    const onChange = (e, description) => {
        setDataItem(prevState => ({
            ...prevState,
            [description]: e.target.checked
        }));
    };

    const handleSubmit = async () => {
        let saveData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            ...dataItem
        };

        let res = await API.saveData(ENTITYNAME.AnalyticsEvents, saveData);
        console.log(res);

        if (res.success) {
            let logData = { event: res.message === "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.ANALYTICS_EVENTS, data: res.data, message: res.message };
            API.SaveLogs(logData);
            toast.success('Successfully updated', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const borderStyle = {
        border: "1px solid #ced4da",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#FAFAFA",
        marginTop: '35px',
        marginBottom: '15px'
    };

    const onSelectAll = (value= false) => {
        // Set all analyticsEvents switches to checked or unchecked
        setDataItem(prevState => {
            let updatedData = { ...prevState };
            analyticsEvents.forEach(x => {
                updatedData[x.Description] = value;
            });
            return updatedData;
        });
    };


    return (
        <Form
            initialValues={dataItem}
            render={(formRenderProps) => (
                <FormElement>
                    <div className="m-1">
                        <EditPageHeader
                            title={lang.analytics_event_page_editpageheader}
                            onSubmit={handleSubmit}
                            onCancel={() => navigate(-1)}
                        />
                        <RoundButton icon="times" style={{float: 'right',}} title="De-Select All" onClick={()=>onSelectAll(false)} />
                        <RoundButton icon="check-square-o" style={{float: 'right', marginRight: '5px',}} title="Select All" onClick={()=>onSelectAll(true)} />
                        <div className="border with-3d-effect" style={borderStyle}>
                            <div className="row ml-3 mb-4">
                                {analyticsEvents.map(x => (
                                    <div className="col-6 p-1 mt-2" key={x._id}>
                                        <div className="row">
                                            <div className="col-6">
                                                <label>{x?.Description}</label>
                                            </div>
                                            <div className="col-6">
                                                <Switch
                                                    checked={!!dataItem[x?.Description] ?? false}
                                                    onChange={(e) => onChange(e, x.Description)}
                                                    size="small"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </FormElement>
            )}
        />
    );
};

export default AnalyticsEventEditForm;
