import React, { useEffect, useState } from 'react'
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { EditPageHeader } from '../../components/EditPageHeader';
import BossDialog from '../../components/BossDialog';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Switch } from '@mui/material';

const PgRatingEditForm = (props) => {
    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        Archive: props.item.Archive ?? false,
        ParentPgRating: props?.item?.ParentPgRating ?? {},
        EnableParentalControl: props?.item?.EnableParentalControl ?? false
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [parentPgRatings, setParentPgRatings] = useState([]);

    useEffect(() => {
        loadCombo()
    }, [])

    const loadCombo = async () => {
        let parentPgRatingRes = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
        setParentPgRatings(parentPgRatingRes.data)
    }

    const onChange = (e) => {
        if (e.target.name === 'Archive') {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        }
        else if(e.target.name == "EnableParentalControl"){
            setDataItem({...dataItem, EnableParentalControl: !dataItem.EnableParentalControl})
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.Description == undefined || dataItem.Description == "") {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {
        if (!isValid()) return;
        const saveData = {
            _id: dataItem._id,
            SID: dataItem.SID,
            Description: dataItem.Description,
            ParentPgRating: dataItem?.ParentPgRating && Object.keys(dataItem?.ParentPgRating).length > 0 ? {
                _id: dataItem?.ParentPgRating?._id,
                Description: dataItem?.ParentPgRating?.Description
            } : {},
            Archive: dataItem.Archive ?? false,
            EnableParentalControl: dataItem.EnableParentalControl ?? false,
            checkDuplicate: true,
            query: [["Description", "=", dataItem.Description]]

        }
        console.log(saveData);
        const res = await API.saveData(ENTITYNAME.PGRating, saveData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.PGRATING, data: res.data, message: res.message };
            API.SaveLogs(logData);
            utility.deleteLocalStorageItem(ENTITYNAME.PGRating);
            props.cancelEdit();
            props.refresh();
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <>
            <BossDialog
                title={props.item.Description ?? lang.pg_rating_dialog_header}
                onClose={props.cancelEdit}
                width={"600px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />

                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <label>{lang.description_label} *</label>
                                <input
                                    name={"Description"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Description}
                                />
                            </div>
                            <div className="col-6">
                                <label>{lang.parent_pg_rating_label}</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    className="form-control form-control-sm"
                                    data={parentPgRatings}
                                    name="ParentPgRating"
                                    textField="Description"
                                    dataItemKey="_id"
                                    onChange={onChange}
                                    value={dataItem.ParentPgRating}
                                />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-2'>
                                <input
                                    style={{ border: "1px solid grey" }}
                                    name={"Archive"}
                                    type='checkbox'
                                    onChange={onChange}
                                />
                                <label className="ml-2">{lang.archive}</label>
                            </div>
                            <div className='col-6'>
                            <Switch
                                    name="EnableParentalControl"
                                    label={lang.parental_control_label}
                                    onChange={onChange}
                                    checked={dataItem.EnableParentalControl}
                                    value={dataItem.EnableParentalControl}
                                    size='small'
                                />
                                <label className="ml-2">{lang.parental_control_label}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog >
        </>
    )
}

export default PgRatingEditForm
