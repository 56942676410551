import React, { useState } from 'react'
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from '../../framework/constant/constant'
import { toast } from 'react-toastify'
import { utility } from '../../framework/utility/utilityProvider'
import { useTranslation } from '../../locale/useTranslation'
import { EditPageHeader } from '../../components/EditPageHeader'
import BossDialog from '../../components/BossDialog'

function ApplicationConfigurationForm(props) {

    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Key: props.item.copy ? 'copy of ' + props.item.Key : props.item.Key ?? '',
        Value: props.item.Value ?? '',
        Archive: props.item.Archive ?? false,
    }
    const [dataItem, setDataItem] = useState(blankDataItem);

    const onChange = (e) => {

        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const handleSubmit = async () => {
        const saveData = {
            _id: dataItem._id,
            Key: dataItem.Key,
            Value: dataItem.Value?.toLowerCase() == 'true' ? true : dataItem.Value?.toLowerCase() == 'false' ? false : dataItem.Value,
            Archive: dataItem.Archive ?? false,
        }

        console.log(saveData);

        let res = await API.saveData(ENTITYNAME.ApplicationConfiguration, saveData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.APPLICATION_CONFIGURATION, data: res.data, message: res.message };
            API.SaveLogs(logData);
            props.cancelEdit();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.ApplicationConfiguration);
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    return (
        <>
            <BossDialog
                title={props.item._id > 0 ? props.item.Key : "Application Configuration"}
                onClose={props.cancelEdit}
                width={"400px"}
                height={"max-content"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row mt-2">
                            <div className="col">
                                <label>{lang.key_label}</label>
                                <input
                                    name={"Key"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Key}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="TabView">{lang.value_label}</label>
                                <textarea
                                    name={"Value"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Value}
                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col mt-3">
                                <input
                                    type={"checkbox"} name={"Archive"} style={{ marginTop: "10px" }}
                                    onChange={(e) => onChange(e)}
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive} />
                                <label className="ml-1">{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>

            </BossDialog>
        </>)
}

export default ApplicationConfigurationForm
