/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import BossDialog from "../../components/BossDialog";

export const MaterialContentEditForm = (props) => {

  const [mediaCategory, setMediaCategory] = useState([]);
  const lang = useTranslation();

  const blankDataItem = {
    ...props.item,
    MediaCategory: props.item.MediaCategory ?? {},
    Description: props.item.copy ? 'copy of ' + props.item.Description : props.item.Description ?? "",
    NextNumber: props.item.NextNumber ?? 1,
    Prefix: props.item.Prefix ?? '',
    Length: props.item.Length ?? 5,
    Archive: props.item.Archive ?? false,
  }

  const [dataItem, setDataItem] = useState(blankDataItem);

  useEffect(() => {
    loadcombo();
  }, [])

  const loadcombo = async () => {
    let mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } });
    let mediaCategoryData = mediaCategoryRes.data.filter((x) => x.Archive != true && x.isLong == true);
    setMediaCategory(mediaCategoryData);
  }

  //function to check the validation in form
  function isValid() {

    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.MediaCategory).length == 0) {
      toast.error(`${lang.please_select_media_category_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Prefix == undefined || dataItem.Prefix == "") {
      toast.error(`${lang.please_enter_prefix_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.NextNumber == undefined || dataItem.NextNumber == "") {
      toast.error(`${lang.please_enter_next_number_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(dataItem.NextNumber <= 0){
      toast.error(`${lang.next_number_can_not_be_negative_or_zero_error_meessage}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(!dataItem.Length || dataItem.Length <= 0 || dataItem.Length > 10){
      toast.error(`${lang.number_length_should_be_between_1_to_10_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {

    if (isValid()) {
      const localData = {
        _id: dataItem._id,
        SID: dataItem.SID ?? 0,
        MediaCategory: {
          _id: dataItem.MediaCategory._id,
          SID: dataItem.MediaCategory.SID,
          Description: dataItem.MediaCategory.Description,
          MediaCategorySID: dataItem.MediaCategory.MediaCategorySID
        },
        Description: dataItem.Description,
        Prefix: dataItem.Prefix,
        NextNumber: parseInt(dataItem.NextNumber) ?? 1,
        Length: dataItem.Length,
        Archive: dataItem.Archive ?? false,
        checkDuplicate: true,
        query: [["Prefix", "=", dataItem.Prefix]]

      }
      console.log(localData);
      let res = await API.saveData(ENTITYNAME.MaterialContent, localData);
      if (res.success) {
        let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.MATERIALCONTENT, data: res.data, message: res.message };
        API.SaveLogs(logData);
        utility.deleteLocalStorageItem(ENTITYNAME.MaterialContent);
        props.cancelEdit();
        props.refresh();
      } else if (res.code == 413) {
        toast.error(lang.entry_already_exists_in_system_for_same_prefix_and_suffix_error_message, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }

  const onChangeForm = (e) => {

    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

  }

  return (
    <>
      <BossDialog
        title={props.item.SID > 0 ? props.item.Description : "Material Content"}
        onClose={props.cancelEdit}
        width={"770px"}
      >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
        <Form
          initialValues={dataItem}
          render={(formRenderProps) => (
            <FormElement>
              <div className="row mt-2">
                <div className="col-12">

                  <div className="row">
                    <div className="col-6">
                      <label>{lang.description_label} *</label>
                      <input
                        name={"Description"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChangeForm}
                        value={dataItem.Description}
                      />
                    </div>
                    <div className="col-6">
                      <label>{lang.media_category_label} *</label>
                      <DropDownList
                        style={{ backgroundColor: 'white' }}
                        name={"MediaCategory"}
                        textField={"Description"}
                        value={dataItem.MediaCategory}
                        data={mediaCategory}
                        onChange={onChangeForm}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <label>{lang.prefix_label} *</label>
                      <input
                        name={"Prefix"}
                        type="text"
                        className="form-control form-control-sm"
                        value={dataItem.Prefix}
                        onChange={onChangeForm}
                      />
                    </div>
                    <div className="col-6" >
                      <label>{lang.next_number_label} *</label>
                      <input
                        name="NextNumber"
                        className="form-control form-control-sm"
                        value={dataItem.NextNumber}
                        type="number"
                        onChange={onChangeForm}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">

                    <div className="col-6" >
                      <label>{lang.number_length_label} *</label>
                      <input
                        className="form-control form-control-sm"
                        name="Length"
                        value={dataItem.Length}
                        type="number"
                        onChange={onChangeForm}
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <input type={"checkbox"} name={"Archive"} style={{ marginTop: "10px" }}
                        onChange={(e) => onChangeForm(e)}
                        value={dataItem.Archive}
                        checked={dataItem.Archive} />
                      <label className="ml-1">{lang.archive}</label>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )}
        />
      </BossDialog>
    </>)
};