/* eslint-disable */
import React, { useState } from 'react'
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE, SUBSCRIPTION_HISTORY_EVENT, SUBSCRIPTION_STATUS } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import CollectionSelection from '../../framework/forms/CollectionSelection';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import moment from 'moment';
import { DateCell, DateOnlyCell, DateTimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { Switch } from '@mui/material';
import MyStatusCell from '../../components/MyStatusCell';
import BossDialog from '../../components/BossDialog';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';

const SubscriberEditForm = (props) => {

    const lang = useTranslation();
    const currentDate = moment(new Date()).format('YYYY-MM-DD');

    const [status, setStatus] = useState([]);
    const [segments, setSegments] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [historyData, setHistoryData] = useState([]);
    const [loginHistoryData, setLoginHistoryData] = useState([]);
    const [showPackageCollection, setShowPackageCollection] = useState(false);
    const [tabNumber, setTabNumber] = useState("1");
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const [showPackageStartDatePopup, setShowPackageStartDatePopup] = useState(false);
    const [packageData, setPackageData] = useState({
        StartDate: currentDate
    });
    const [removedPlans, setRemovedPlans] = useState([]);
    const [devicesData, setDevicesData] = useState([])


    const blankDataItem = {
        SubscriptionID: props.item.SubscriptionID ?? '',
        Name: props.item?.copy ? "Copy of " + props.item.Name : props.item.Name ?? '',
        RMN: props.item.RMN ?? '',
        Email: props.item.Email ?? '',
        PaymentPlan: props.item.PaymentPlan ?? [],
        LastUpdated: props.item.LastUpdated ?? (new Date()).getTime(),
        Segments: props.item.Segments ?? [],
        AutoRenew: props.item.AutoRenew ?? false,
        Archive: props.item.Archive ?? false,
        EnableParentalControl: props?.item?.EnableParentalControl ?? false
    }

    useEffect(() => {
        pageRender();
    }, []);

    const pageRender = async () => {
        await loadcombo();
        if (props.item.SID > 0) {
            await loadEditData();
        }
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [showCoupon, setShowCoupon] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabNumber(`${newValue}`);
    };

    const loadcombo = async () => {
        const statusType = Object.keys(SUBSCRIPTION_STATUS).map((item, index) => {
            return { "Description": item, "SID": SUBSCRIPTION_STATUS[item] }
        });
        setStatus(statusType);

        const segmentsRes = await API.getDataLookup(ENTITYNAME.OttSegment, { query: ["Archive", '=', false], sort: { Description: 1 } });
        setSegments(segmentsRes.data);
    }

    const loadEditData = async () => {

        let linkData = {
            link_collection: 'coupon',
            link_field: 'Coupons',
        }
        const res = await API.getEntity(ENTITYNAME.Subscriber, parseInt(props.item.SID), linkData);

        if (res.success) {
            let item = {
                ...dataItem,
                ...res.data,
                PaymentPlan: props.item.PaymentPlan
            }
            setHistoryData(props.item?.History ?? []);
            setLoginHistoryData(props.item?.LoginHistory ?? []);
            setDataItem(item);
            setGridData(res.data?.Coupons ?? []);
            setDevicesData(res.data?.loggedInDevice ?? [])
        }
    }


    const onChangeForm = (e) => {
        if (e.target.name === "AutoRenew") {
            setDataItem({ ...dataItem, AutoRenew: !dataItem.AutoRenew });
        }else if(e.target.name === "EnableParentalControl"){
           setDataItem({...dataItem, EnableParentalControl: !dataItem.EnableParentalControl})
        } 
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.SubscriptionID == "" || dataItem.SubscriptionID == undefined) {
            toast.error(`${lang.please_enter_subscription_id_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Name == "" || dataItem.Name == undefined) {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.RMN == "" || dataItem.RMN == undefined) {
            toast.error(`${lang.please_enter_rmn_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Email == "" || dataItem.Email == undefined || !emailRegex.test(dataItem.Email)) {
            toast.error(`${lang.please_enter_email_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Segments.length == 0) {
            toast.error(`${lang.please_select_segment_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        // if (dataItem.PaymentPlan.length == 0) {
        //     toast.error(`Select Payment Plan`, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     return false;
        // }
        return true;

    }

    const handleSubmit = async () => {

        if (isValid()) {
            let saveData = {
                SID: dataItem.SID,
                SubscriptionID: dataItem.SubscriptionID,
                Name: dataItem.Name,
                RMN: dataItem.RMN,
                Email: dataItem.Email,
                Segments: dataItem.Segments.map((x) => {
                    return {
                        _id: x._id,
                        SID: x.SID,
                        Description: x.Description
                    }
                }),
                Coupons: gridData && gridData?.length > 0 ? gridData?.map(x => x._id) : [],
                Archive: dataItem.Archive ?? false,
                loggedInDevice: devicesData,
                EnableParentalControl: dataItem?.EnableParentalControl ?? false,
                checkDuplicate: true,
                query: [["RMN", "=", dataItem.RMN]]
            }

            if (props.item.SID > 0) {
                saveData._id = props.item._id
            }

            if (removedPlans.length > 0) {
                saveData.PaymentPlan = dataItem.PaymentPlan;
            }

            const res = await API.saveData(ENTITYNAME.Subscriber, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.SUBSCRIBER, data: res.data, message: res.message };
                API.SaveLogs(logData);
                utility.deleteLocalStorageItem(ENTITYNAME.Subscriber);

                if (removedPlans.length > 0) {
                    removedPlans.map((plan) => {
                        API.updateSubscriberPackage({
                            Subscriber_id: res.data._id.toString(),
                            Package_id: plan._id.toString(),
                            PackStartDate: plan.StartDate,
                            Event: utility.getKeyByValue(SUBSCRIPTION_HISTORY_EVENT, SUBSCRIPTION_HISTORY_EVENT.Deleted),
                            DeletedPlan: plan
                        })
                    })

                } else {
                    //UPDATE SUBSCRIBER HISTORY
                    dataItem.PaymentPlan.filter((y) => y.new).map((x) => {
                        API.updateSubscriberPackage({
                            Subscriber_id: res.data._id.toString(),
                            Package_id: x._id,
                            PackStartDate: x.StartDate,
                            Event: utility.getKeyByValue(SUBSCRIPTION_HISTORY_EVENT, SUBSCRIPTION_HISTORY_EVENT.Added)
                        })
                    })
                }

                props.cancelEdit();
                props.refresh();
                return;
            } else {
                if (res.code = 413) {
                    toast.error('Mobile Number already registered in system.', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                } else {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });

                }

            }
        }
    }

    const handleCouponData = (dataList) => {
        let newData = utility.checkDuplicateInData(dataList, gridData, "Name")
        setGridData([...gridData, ...newData]);
    }

    const removeCoupon = (item) => {
        setGridData(gridData.filter((x) => x._id != item._id));
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const removePlan = (item) => {

        if (!item.dataItem.new) {
            setRemovedPlans([...removedPlans, item.dataItem]);
        }

        let temp = [...dataItem.PaymentPlan]
        temp.splice(item.dataIndex, 1)
        setDataItem({ ...dataItem, PaymentPlan: temp });
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const MyPaymentPlanCommandCell = (props) => (
        <div className="mt-1" style={{ display: "flex" }}>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removePlan(props), () => { })} />
        </div>
    )

    const MyCouponCommandCell = (props) => (
        <div className="mt-1" style={{ display: "flex" }}>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeCoupon(props.dataItem), () => { })} />
        </div>
    )

    const handleAddPackage = (dataList) => {
        if (dataList.length > 0) {
            setShowPackageStartDatePopup(true);
            if (dataItem.PaymentPlan.length > 0) {
                setPackageData({
                    ...dataList[0],
                    StartDate: moment(new Date(dataItem.PaymentPlan[dataItem.PaymentPlan.length - 1].ExpiryDate)).format('YYYY-MM-DD')
                })
            } else {
                setPackageData({
                    ...dataList[0],
                    StartDate: packageData.StartDate
                })
            }
        }

    }

    const onSetPackageData = () => {

        console.log(packageData);
        let startDate = new Date(moment(new Date(packageData.StartDate)).format("YYYY-MM-DD"));

        //LESS THAN CURRENT DATE VALIDATION
        if (startDate.getTime() < new Date(currentDate).getTime()) {
            toast.error(`Please enter start date greater than current date`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //VALIDATION FOR OVERLAPPING DATE
        if (dataItem.PaymentPlan.length > 0) {
            let isStartDateOverlapping = dataItem.PaymentPlan.some((x) => x.ExpiryDate > startDate.getTime());
            if (isStartDateOverlapping) {
                toast.error(`Please enter start date greater than expiry date of existing plans`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
        }

        if (Object.keys(packageData).length > 0) {
            let newData = utility.checkDuplicateInData([packageData], dataItem.PaymentPlan, "Description");
            let expiryDate = new Date(moment(new Date(packageData.StartDate)).add(packageData.OttPeriod.Days, "d").format("YYYY-MM-DD"))
            setDataItem({
                ...dataItem,
                PaymentPlan: [...dataItem.PaymentPlan,
                ...newData.map((x) => {
                    return {
                        _id: x._id,
                        Description: x.Description,
                        Amount: x.Amount,
                        StartDate: startDate.getTime(),
                        ExpiryDate: expiryDate.getTime(),
                        Status: new Date(currentDate).getTime() > startDate.getTime() && new Date(currentDate).getTime() < expiryDate.getTime() ? 'Active' : 'Deactive',
                        MaxDevices: x?.MaxDevices,
                        new: true
                    }
                })]
            });
        }
        setShowPackageStartDatePopup(false);
    }

    const Mystatuscell = (props) => {

        let tileBackColor = props.dataItem[props.field] == 'Active' ? "#63aa43" : "#E72929";

        if (props.field == 'Event') {
            tileBackColor = "#006769"
        }

        return <MyStatusCell title={props.dataItem[props.field]} tileBackColor={tileBackColor} />

    }

    const deleteSelectedDevice = (index) => {
        let removeData = [...devicesData.slice(0, index), ...devicesData.slice(index + 1, devicesData.length)]
        setDevicesData(removeData);
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const MyDeviceCommandCell = (cell) => {
        return <td>
            <RoundButton icon="trash" Title={lang.delete_button_tooltip} onClick={() => ConfirmDeleteAlert(() => deleteSelectedDevice(cell.dataIndex), () => { })} />
        </td>
    }

    return (
        <>
            <BossDialog
                title={props.item.Name ?? `${lang.create_subscriber_dialog_header}`}
                onClose={props.cancelEdit}
                width={"900px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} showStatusCell={true} status={props.item.Status} statusCellColor={props.item.Status == 'Active' ? 'green' : 'red'} />
                <Form
                    initialValues={dataItem}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.subscription_id_label} *</label>
                                            <input
                                                name={"SubscriptionID"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChangeForm}
                                                value={dataItem.SubscriptionID}
                                                disabled={props.item.SID > 0}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.name_label} *</label>
                                            <input
                                                name={"Name"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChangeForm}
                                                value={dataItem.Name}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className='col-6'>
                                            <label>{lang.rmn_label} *</label>
                                            <input
                                                name={"RMN"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChangeForm}
                                                value={dataItem.RMN}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.email_label} *</label>
                                            <input
                                                name={"Email"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChangeForm}
                                                value={dataItem.Email}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label htmlFor="TabView">{lang.segment_label} *</label>
                                            <MultiSelect
                                                style={{ backgroundColor: "white" }}
                                                data={segments}
                                                name="Segments"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.Segments}
                                                onChange={onChangeForm}
                                            />
                                        </div>
                                        <div className="col mt-3">
                                            <Field
                                                name={"AutoRenew"}
                                                component={Switch}
                                                label={lang.auto_renew_subscription_label}
                                                onLabel={lang.auto_renew_subscription_label}
                                                offLabel={lang.auto_renew_subscription_label}
                                                size={"medium"}
                                                onChange={(e) => onChangeForm(e)}
                                                checked={dataItem.AutoRenew}
                                            />
                                            <label htmlFor="TabView">{lang.auto_renew_subscription_label}</label>
                                            <Switch
                                                name="EnableParentalControl"
                                                className='ml-3'
                                                label={lang.parental_control_label}
                                                onChange={onChangeForm}
                                                checked={dataItem.EnableParentalControl}
                                                value={dataItem.EnableParentalControl}
                                                size={"medium"}
                                            />
                                            <label>{lang.parental_control_label}</label>
                                        </div>
                                        <div className='col-6 mt-2 ml-2'>
                                            <Field
                                                style={{ border: "1px solid grey" }}
                                                name={"Archive"}
                                                component={Checkbox}
                                                label={lang.archive}
                                                onChange={onChangeForm}
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ margin: "0px" }}>
                                        <Box sx={{ width: '100%', typography: 'body1' }}>
                                            <TabContext value={tabNumber}>
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                                        <Tab label={lang.payment_plan_label} value="1" />
                                                        <Tab label={lang.coupon_label} value="2" />
                                                        <Tab label={lang.subscriber_subscription_history} value="3" />
                                                        <Tab label={lang.subscriber_login_history} value="4" />
                                                        <Tab label={lang.devices_label} value="5" />
                                                    </TabList>
                                                </Box>
                                                <TabPanel value={"1"} style={{ padding: '10px' }}>
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <AddRoundButton onClick={() => setShowPackageCollection(true)} title={'Add Payment Plan'} />
                                                        </div>
                                                        <div className="col-4">
                                                            <h5 className="pull-right">{lang.payment_plan_label}</h5>
                                                        </div>
                                                    </div>
                                                    <Grid data={dataItem.PaymentPlan} style={{ height: '180px', margin: '5px 0px' }}>
                                                        <GridColumn cell={MyPaymentPlanCommandCell} width={"40px"} locked={true} />
                                                        <GridColumn field="Description" title={lang.description_column} editable={false} />
                                                        <GridColumn field="Amount" title={lang.amount_column} editable={false} />
                                                        <GridColumn field="StartDate" title={"Start Date"} cell={DateOnlyCell} editable={false} />
                                                        <GridColumn field="ExpiryDate" title={"Expiry Date"} cell={DateOnlyCell} editable={false} />
                                                        <GridColumn field="Status" title={"Status"} cell={Mystatuscell} editable={false} />
                                                        <GridColumn field="MaxDevices" title={"Max Device"} editable={false} />
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={"2"} style={{ padding: '10px' }}>
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <AddRoundButton onClick={() => setShowCoupon(true)} title={lang.add_coupon_tooltip} />
                                                        </div>
                                                        <div className="col-4">
                                                            <h5 className="pull-right">{lang.coupon_label}</h5>
                                                        </div>
                                                    </div>
                                                    <Grid data={gridData} style={{ height: '180px', margin: '5px 0px' }}>
                                                        <GridColumn cell={MyCouponCommandCell} width={"35px"} locked={true} />
                                                        <GridColumn field="Name" title={lang.name_column} editable={false} />
                                                        <GridColumn field="Code" title={lang.code_column} editable={false} />
                                                        <GridColumn field="DiscountType.Type" title={lang.discount_type_column} editable={false} />
                                                        <GridColumn field="DiscountValue" title={lang.discount_value_column} editable={false} />
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={"3"} style={{ padding: '5px' }}>
                                                    <div className="row">
                                                        <div className="col-8">
                                                        </div>
                                                        <div className="col-4">
                                                            <h5 className="pull-right">{lang.subscriber_subscription_history}</h5>
                                                        </div>
                                                    </div>
                                                    <Grid data={historyData} style={{ height: '180px', margin: '5px 0px' }}>
                                                        <GridColumn field="Status" title={"Status"} cell={Mystatuscell} editable={false} width={100} />
                                                        <GridColumn field="Event" title={"Event"} cell={Mystatuscell} editable={false} width={100} />
                                                        <GridColumn field="selectedPlan.Description" title={"Plan"} editable={false} width={120} />
                                                        <GridColumn field="selectedPlan.Amount" title={'Plan Amount'} editable={false} width={100} />
                                                        <GridColumn field="timeStramp" title={"Timestamp"} cell={DateTimeCell} editable={false} width={180} />
                                                        <GridColumn field="selectedPlan.StartDate" title={"StartDate"} cell={DateOnlyCell} editable={false} width={100} />
                                                        <GridColumn field="selectedPlan.ExpiryDate" title={"Expiry Date"} cell={DateOnlyCell} editable={false} width={100} />
                                                        <GridColumn field="orderId" title={"Order Id"} editable={false} width={120} />
                                                        <GridColumn field="paymentId" title={"Payment Id"} editable={false} width={120} />
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={"4"} style={{ padding: '5px' }}>
                                                    <div className="row">
                                                        <div className="col-8">
                                                        </div>
                                                        <div className="col-4">
                                                            <h5 className="pull-right">{lang.subscriber_login_history}</h5>
                                                        </div>
                                                    </div>
                                                    <Grid data={loginHistoryData} style={{ height: '180px', margin: '5px 0px' }}>
                                                        <GridColumn field="timeStramp" title={"Last Login"} cell={DateTimeCell} editable={false} />
                                                        <GridColumn field="Platform" title={lang.platform_label} editable={false} />
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={"5"} style={{ padding: '5px' }}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h5 className="pull-right">{lang.devices_label}</h5>
                                                        </div>
                                                    </div>
                                                    <Grid data={devicesData} style={{ height: '180px', margin: '5px 0px' }}>
                                                        <GridColumn editable={false} cell={MyDeviceCommandCell} width={"60px"} />
                                                        <GridColumn field="serialNumber" title={lang.serial_number_column} editable={false} />
                                                        <GridColumn field="deviceName" title={lang.device_name_column} editable={false} />
                                                        <GridColumn field="lastLoginTime" title={lang.last_login_time_column} cell={DateTimeCell} editable={false} />
                                                    </Grid>
                                                </TabPanel>
                                            </TabContext>
                                        </Box>
                                    </div>
                                    {showCoupon && <CollectionSelection entityname={ENTITYNAME.Coupon} title={lang.select_coupon_collection} closeForm={() => setShowCoupon(false)} setDataList={handleCouponData} width={"50vw"} height={"60vh"} />}
                                    {showPackageCollection && <CollectionSelection title={lang.select_package_header} setDataList={handleAddPackage} entityname={ENTITYNAME.PaymentPlan} closeForm={() => setShowPackageCollection(false)} mode={'single'} width={"50vw"} height={"64vh"} />}
                                    {showPackageStartDatePopup && <BossDialog
                                        title={'Plan Start Date'}
                                        onClose={() => setShowPackageStartDatePopup(false)}
                                        width={"300px"}
                                    >
                                        <EditPageHeader title={""} onSubmit={onSetPackageData} onCancel={() => setShowPackageStartDatePopup(false)} showTitle={false} />
                                        <Form
                                            initialValues={dataItem}
                                            render={(formRenderProps) => (
                                                <FormElement>
                                                    <div className='row'>

                                                        <div className='col-12'>
                                                            <label>{lang.start_date_label} *</label>
                                                            <input
                                                                type="date"
                                                                className="form-control form-control-sm"
                                                                name="StartDate"
                                                                value={packageData.StartDate}
                                                                onChange={(e) => {
                                                                    setPackageData({
                                                                        ...packageData,
                                                                        StartDate: e.target.value
                                                                    })
                                                                }}
                                                                min={moment(new Date()).format('YYYY-MM-DD')}
                                                            />
                                                        </div>
                                                    </div>

                                                </FormElement>
                                            )}
                                        />
                                    </BossDialog >}
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </BossDialog >
        </>
    )
}

export default SubscriberEditForm;